import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-mat-email-card',
    templateUrl: './app-mat-email-card.component.html',
    styleUrls: ['./app-mat-email-card.component.scss'],
    standalone: false
})
export class AppMatEmailCardComponent implements OnInit {

    @Input() email: string;

    constructor() { }

    ngOnInit(): void {
    }

    get emailAddress(): string {
        return (this.email && this.email.length) ? this.email : 'Not specified';
    }

}
