// This file contains the defaults for app config, to allow defaults to be set before the app loads config from the server
export const config = {

  'applicationEnabled': true,
  'dashboardModuleEnabled': true,
  'patientModuleEnabled': true,
  'patientModuleProfileEnabled': true,
  'patientModuleClinicalEnabled': true,
  'patientModuleSmsEnabled': true,
  'patientModuleEmailEnabled': true,
  'patientModuleProceduresEnabled': true,
  'patientModuleUploadEnabled': true,
  'patientModuleReportsEnabled': true,
  'patientModuleComplianceEnabled': false,
  'patientModuleBillingEnabled': false,
  'patientDemographicModuleEnabled': true,
  'patientClinicalModuleEnabled': true,
  'patientSmsModuleEnabled': true,
  'patientEmailModuleEnabled': true,
  'patientProceduresModuleEnabled': true,
  'patientUploadModuleEnabled': true,
  'patientReportsModuleEnabled': true,
  'patientBillingModuleEnabled': false,
  'reportBuilderModuleEnabled': true,
  'complianceModuleEnabled': false,
  'calendarModuleEnabled': true,
  'settingsModuleEnabled': true,
  'signupModuleEnabled': false,
  'adminModuleEnabled': true,
  'minimumApplicationVersion': '1.0.0',
  'logFullApiErrorsToConsole': true,
  'readonly': false
};
