import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject, Subscription} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {DatePipe} from '@angular/common';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import {FlatPickrOutputOptions} from 'angularx-flatpickr/lib/flatpickr.directive';

@Component({
    exportAs: 'dateTimeInput',
    selector: 'app-mat-date-time-input',
    templateUrl: './mat-date-time-input.component.html',
    styleUrls: ['./mat-date-time-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: MatDateTimeInputComponent }],
    standalone: false
})
export class MatDateTimeInputComponent implements MatFormFieldControl<Date | null>, OnInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  // tslint:disable-next-line:no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() defaultHour = 12;
  @Input() defaultMinute = 0;
  @Input() defaultIncrement = 15;
  @Input() enableTime = true;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @ViewChild('flatpickr') flatpickr: FlatPickrOutputOptions;
  @HostBinding() id = `app-mat-date-time-input-${MatDateTimeInputComponent.nextId++}`;
  focused = false;
  dateInput: UntypedFormGroup;
  stateChanges = new Subject<void>();
  internalValueChangesSubscription: Subscription;
  controlType = 'app-mat-date-time-input';
  autofilled?: boolean;
  defaultLocale = { firstDayOfWeek: 1 };
  confirmDatePlugin = confirmDatePlugin({
    confirmText: 'Confirm Date',
    confirmIcon: '',
    showAlways: true,
  });
  private _touched: boolean;

  constructor(private _elementRef: ElementRef, private datePipe: DatePipe, private formBuilder: UntypedFormBuilder, @Optional() @Self() public ngControl: NgControl) {
    this.dateInput = this.formBuilder.group({
      dateTime: ['', Validators.required]
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty() {
    const controlValue = this.dateInput.value;
    return !controlValue.dateTime;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return !this.empty;
  }

  get errorState(): boolean {
    return this.ngControl && this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  @Input()
  get value(): Date | null {
    const controlValue = this.dateInput.value;
    if (controlValue.dateTime) {
      return new Date(controlValue.dateTime);
    }
    return null;
  }

  set value(date: Date | string | null) {
    if (date !== null && ((date instanceof Date) || (typeof date === 'string' && date.length > 0))) {
      date = new Date(date);
      if (this.enableTime) {
        this.dateInput.setValue({ dateTime: this.datePipe.transform(date, 'YYYY-MM-dd HH:mm') });
      } else {
        this.dateInput.setValue({ dateTime: this.datePipe.transform(date, 'YYYY-MM-dd') });
      }
    } else {
      this.dateInput.setValue({ dateTime: null });
    }
    this.onChange(date);
    this.stateChanges.next();
  }

  get dateFormat() {
    return this.enableTime ? 'Y-m-d H:i' : 'Y-m-d';
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.dateInput.disable() : this.dateInput.enable();
    this.stateChanges.next();
  }

  onChange = (date) => {
  }

  onTouched = () => {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.internalValueChangesSubscription?.unsubscribe();
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.app-mat-date-time-input');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    const targetTagName = (event.target as Element).tagName.toLowerCase();
    if (targetTagName !== 'input' && targetTagName !== 'button') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(date: Date): void {
    this.value = date;
  }

  togglePicker() {
    this.flatpickr.instance.toggle();
  }

  openPicker() {
    this.flatpickr.instance.open();
  }

  closePicker() {
    this.flatpickr.instance.close();
  }

  onDateChange(changeEvent: FlatPickrOutputOptions) {
    this.value = new Date(changeEvent.dateString);
  }
}
