import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class FormatterService {

    constructor(private datePipe: DatePipe) { }

    private static textToInitialCap(text: string): string {
        if (text.length === 0) { return text; }
        if (text.length === 1) { return text.toUpperCase(); }
        return text[0].toUpperCase() + text.slice(1);
    }

    private static practiceNumberFormatter(practiceNumber: string): string {
        const cleaned = practiceNumber.replace(/\D/g, '');
        const size = cleaned.length;
        let formattedNumber = '';
        if (size < 3) {
            formattedNumber = cleaned;
        } else if (size < 7) {
            formattedNumber = cleaned.substring(0, 3) + ' ' + cleaned.substring(3, 6);
        } else {
            formattedNumber = cleaned.substring(0, 3) + ' ' + cleaned.substring(3, 6) + ' ' + cleaned.substring(6, 13);
        }
        return formattedNumber;
    }

    private static phoneNumberFormatter(phoneNumber: string): string {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const size = cleaned.length;
        let formattedNumber = '';
        if (size < 3) {
            formattedNumber = cleaned;
        } else if (size < 7) {
            formattedNumber = cleaned.substring(0, 3) + ' ' + cleaned.substring(3, 6);
        } else {
            formattedNumber = cleaned.substring(0, 3) + ' ' + cleaned.substring(3, 6) + ' ' + cleaned.substring(6, 10);
        }
        return formattedNumber;
    }

    private static automaticTextFormatter(text: string): string {
        const SPACE = ' ';
        const NEWLINE = '\n';
        text = text || '';
        text = text.toLowerCase();
        let textTokens = text.split(' ');
        let formattedText = '';
        let tokenCount = textTokens.length;
        for (let tokenIndex = 0; tokenIndex < tokenCount; tokenIndex++) {
            formattedText += FormatterService.textToInitialCap(textTokens[tokenIndex]);
            if (tokenIndex < (tokenCount - 1)) { formattedText += SPACE; }
        }
        textTokens = formattedText.split(/\r?\n/);
        formattedText = '';
        tokenCount = textTokens.length;
        for (let tokenIndex = 0; tokenIndex < tokenCount; tokenIndex++) {
            formattedText += FormatterService.textToInitialCap(textTokens[tokenIndex]);
            if (tokenIndex < (tokenCount - 1)) { formattedText += NEWLINE; }
        }
        return formattedText;
    }

  // tslint:disable-next-line:no-shadowed-variable
    format(value: any, pattern: pattern): string {
        const newValue: string = value?.toString() ?? '';
        if (pattern === 'auto') {
            return FormatterService.automaticTextFormatter(newValue);
        }
        if (pattern === 'date') {
            return this.dateTextFormatter(newValue);
        }
        if (pattern === 'phone_number') {
            return FormatterService.phoneNumberFormatter(newValue);
        }
        if (pattern === 'practice_number') {
            return FormatterService.practiceNumberFormatter(newValue);
        }
        if (pattern === 'patient_number') {
            return newValue.toString().padStart(4, '0');
        }
        if (pattern === 'all_caps') {
          return newValue.toUpperCase();
        }
        return newValue;
    }

    private dateTextFormatter(text: string): string {
        if (text == null || text.length === 0) { return text; }
        const date = new Date(text);
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
}

export type pattern =
    | 'auto'
    | 'patient_number'
    | 'default'
    | 'date'
    | 'all_caps'
    | 'phone_number'
    | 'practice_number';
