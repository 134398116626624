import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toasts: Toast[] = [];
  private toastsSubject: Subject<Toast> = new Subject();
  toasts$: Observable<any> = this.toastsSubject.asObservable();

  show(toast: Toast) {
    this.toasts.push(toast);
    this.toastsSubject.next(toast);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}

export interface Toast {
  message: string;
  options?: ToastOptions;
}

export interface ToastOptions {
  delay?: number;
  classname?: string;
}

