<div class="app-loading">
  <div class="logo">
    <svg class="spinner" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
  </div>
  <div class="progress-holder" *ngIf="showProgress">
    <h5 class="text-white">Loading...</h5>
    <div class="progress">
      <mat-progress-bar mode="determinate" color="primary" [value]="progress"></mat-progress-bar>
    </div>
  </div>
  <ng-content></ng-content>
  </div>
