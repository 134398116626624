import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'patientNumberPad',
    standalone: false
})
export class PatientNumberPadPipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    if (!value) {
      return value.toString();
    }
    return value.toString().padStart(4, '0');
  }

}
