import {Injectable} from '@angular/core';
import {Invite} from './entities/invite';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InvitesCollectionResponse} from './entities/invites-collection-response';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class InviteRepositoryService {

  constructor(private api: ApiService) {
  }

  getAllInvites(): Observable<Invite[]> {
    return this.api.get<InvitesCollectionResponse>('/users/invites')
      .pipe(map(response => response.items));
  }
}
