<mat-form-field>
  <mat-label>Tags</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let tag of tags" (removed)="remove(tag)">
      {{tag.tag}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="Enter tag"  cdkOverlayOrigin #trigger="cdkOverlayOrigin"
           appSearchBox [formControl]="formControl" [searchObservable]="searchObservable" (results)="onSearchResults($event)"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="false"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
</mat-form-field>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayScrollStrategy]="scrollStrategy" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
             [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayHasBackdrop]="false" [cdkConnectedOverlayOpen]="hasResults" [cdkConnectedOverlayPanelClass]="'search-overlay'">
  <mat-list class="mat-elevation-z8">
    <mat-list-item *ngFor="let searchResult of searchResults" (click)="selectItem(searchResult)"> {{searchResult.tag}} </mat-list-item>
  </mat-list>
</ng-template>
