import {inject, Injectable} from '@angular/core';
import {ApiService} from '../base/repository/api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdminApiService} from '../base/repository/admin-api.service';
import {Letterhead} from './entities/letterhead';
import {LetterheadCollectionResponse} from './entities/letterhead-collection-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';

@Injectable({
  providedIn: 'root'
})
export class PracticeLetterheadRepositoryService {

  private api = inject(ApiService);
  private adminApi = inject(AdminApiService);


  loadAll(): Observable<Letterhead[]> {
    return this.api.get<LetterheadCollectionResponse>('/reports/letterheads')
      .pipe(map(response => response.items));
  }

  adminLoadAll(): Observable<Letterhead[]> {
    return this.adminApi.get<LetterheadCollectionResponse>('/letterheads')
      .pipe(map(response => response.items));
  }

  adminCreate(letterhead: Letterhead): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/letterheads', letterhead)
      .pipe(map(response => response.value));
  }

  adminUpdate(letterhead: Letterhead): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/letterheads/' + letterhead.id, letterhead)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDelete(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/letterheads/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
