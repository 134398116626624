import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-mat-date-time-picker',
    templateUrl: './app-mat-date-time-picker.component.html',
    styleUrls: ['./app-mat-date-time-picker.component.scss'],
    standalone: false
})
export class AppMatDateTimePickerComponent implements OnDestroy {

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() color: ThemePalette = 'primary';
  @Input() parentForm: UntypedFormGroup;
  @Input() name = '';
  @Input() label = 'Date & Time';
  @Input() readonly = false;
  @Input() validate = true;
  @Input() dateFormat = 'Y-m-d H:i';
  @Input() defaultHour = 12;
  @Input() defaultMinute = 0;
  @Input() defaultIncrement = 15;
  @Input() processing = false;
  @Output() action: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  private valueChanges: Subscription;

  constructor() {
  }

  ngOnDestroy(): void {
    this.valueChanges?.unsubscribe();
  }

  get hasActionObservers(): boolean {
    return this.action.observers.length > 0;
  }

  performAction() {
    this.action.emit(true);
  }

}
