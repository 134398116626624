import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'textHighlight',
    standalone: false
})
export class TextHighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, args: string): any {
        if (!value) {
            value = '';
        }
        if (!args) {
            return value;
        }
        args = args.trim();
        const tokens = args.split(' ');
        let replacedValue = value;
        tokens.forEach((token: string) => {
          const escapedToken = token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
          const re = new RegExp('\\b' + escapedToken, 'gi'); // Ensure word boundaries
            const match = value.match(re);
            if (match) {
                replacedValue = replacedValue.replace(re, '--' + match[0] + '__');
            }
        });
        const reStart = new RegExp('--', 'g');
        const reEnd = new RegExp('__', 'g');
        replacedValue = replacedValue.replace(reStart, '<b class="search-value-highlight">');
        replacedValue = replacedValue.replace(reEnd, '</b>');
        return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }

}
