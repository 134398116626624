<div class="container mt-5">
    <div class="card">
        <div class="card-header">
        Module Disabled
        </div>
        <div class="card-body">
        <h5 class="card-title">This module has been disabled by an administrator</h5>
        <p class="card-text">This module has been temporarily disabled by your superuser or the xmedical team. Contact your superuser for more assistance.</p>
        </div>
    </div>
</div>
