import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Comment} from './entities/comment';
import {CommentsCollectionResponse} from './entities/comments-collection-response';
import {CommentTypesCollectionResponse} from './entities/comment-types-collection-response';
import {CommentType} from './entities/comment-type';
import {CommentTemplatesCollectionResponse} from './entities/comment-templates-collection-response';
import {PracticeCommentTemplate} from './entities/practice-comment-template';
import {CommentItemResponse} from './entities/comment-item-response';
import {CommentTypeUsersCollectionResponse} from './entities/comment-type-users-collection-response';
import {CommentTypeUser} from './entities/comment-type-user';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';

@Injectable({
  providedIn: 'root'
})
export class CommentsRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  get(id: number): Observable<Comment> {
    return this.api.get<CommentItemResponse>('comments/' + id)
      .pipe(map(response => response.value));
  }

  create(comment: Comment): Observable<number> {
    return this.api.post<ItemCreatedResponse>('comments', comment)
      .pipe(map(response => response.value));
  }

  update(comment: Comment): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('comments/' + comment.id, comment)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadPatientCommentTypes(): Observable<CommentType[]> {
    return this.api.get<CommentTypesCollectionResponse>('comments/types')
      .pipe(map(response => response.items));
  }

  loadPatientCommentTemplates(): Observable<PracticeCommentTemplate[]> {
    return this.api.get<CommentTemplatesCollectionResponse>('comments/templates')
      .pipe(map(response => response.items));
  }

  updateTemplate(template: PracticeCommentTemplate): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('comments/templates/' + template.id, template)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadPatientComments(patientId: number, typeIds: number[]): Observable<Comment[]> {
    return this.api.get<CommentsCollectionResponse>('comments/patient/' + patientId, {commentTypes: typeIds.join(',')})
      .pipe(map(response => response.items));
  }

  adminCreate(commentType: CommentType): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('comments/types', commentType)
      .pipe(map(response => response.value));
  }

  adminUpdate(id: number, commentType: CommentType): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('comments/types/' + id, commentType)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDelete(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('comments/types/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminLoadCommentTypes(): Observable<CommentType[]> {
    return this.adminApi.get<CommentTypesCollectionResponse>('comments/types')
      .pipe(map(response => response.items));
  }

  adminLoadCommentTypeUsers(typeId: number): Observable<CommentTypeUser[]> {
    return this.adminApi.get<CommentTypeUsersCollectionResponse>('comments/types/' + typeId + '/users')
      .pipe(map(response => response.items));
  }

  adminAddUserToCommentType(userId: string, typeId: number): Observable<boolean> {
    return this.adminApi.post<ItemCreatedResponse>('comments/types/' + typeId + '/users/' + userId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminRemoveUserFromCommentType(userId: string, typeId: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('comments/types/' + typeId + '/users/' + userId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
