import {Injectable, OnDestroy} from '@angular/core';
import { environment } from 'src/environments/environment';
import Pusher, { Channel } from 'pusher-js';
import {SessionStorageService} from '../../modules/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {

  socketId = '';
  private pusher: Pusher;
  private channels: Channel[] = [];

  constructor(private sessionStorage: SessionStorageService) {
    this.pusher = new Pusher(environment.pusher.key, { cluster: environment.pusher.cluster, forceTLS: true });
    const eventsChannel = this.pusher.subscribe('events-channel');
    this.channels.push(eventsChannel);
    this.pusher.connection.bind('connected', (event: any) => {
      console.log('connected', event);
      this.socketId = event.socket_id;
      this.sessionStorage.setItem('socket_id', event.socket_id);
    });
    this.pusher.connection.bind('failed', (error: any) => {
      console.error('connection failed', error);
    });
    this.pusher.connection.bind('unavailable', _ => {
      console.log('connection unavailable');
    });
    this.pusher.connection.bind('connecting', (event: any) => {
      console.log('connecting', event);
    });
    this.pusher.connection.bind('disconnected', (event: any) => {
      console.log('disconnected	', event);
    });
    this.pusher.connection.bind('error', (error: any) => {
      console.error('error', error);
    });
  }

  ngOnDestroy(): void {
    this.channels.forEach(channel => channel.unbind_all());
    this.sessionStorage.deleteItem('socket_id');
  }

  subscribe(channelName: string, eventName: string, callback: any): void {
    const channelNameToLowerCase = channelName.toLowerCase();
    if (this.channels.findIndex(c => c.name === channelNameToLowerCase) === -1) {
      const newChannel = this.pusher.subscribe(channelNameToLowerCase);
      this.channels.push(newChannel);
    }
    const channel = this.channels.find(c => c.name === channelNameToLowerCase);
    channel.bind(eventName, (data: string) => {
      const parsedData = data.length === 0 ? data : JSON.parse(data);
      callback(parsedData);
    });
  }

  unsubscribe(channelName: string, eventName: string): void {
    const channelNameToLowerCase = channelName.toLowerCase();
    if (this.channels.findIndex(c => c.name === channelNameToLowerCase) > -1) {
      const channel = this.channels.find(c => c.name === channelNameToLowerCase);
      channel.unbind(eventName);
    }
  }
}
