import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Utilities} from '../Utilities';

@Directive({
    selector: '[appBackgroundColourOpacity]',
    standalone: false
})
export class BackgroundColourOpacityDirective implements AfterViewInit, OnChanges {
  private element: any;
  @Input() backgroundColor: string;
  @Input() opacity = 0.5;

  constructor(private elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.updateColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateColor();
  }

  private updateColor() {
    if (this.backgroundColor.length === 0) {
      return;
    }
    setTimeout(() => {
      const colorComponents = Utilities.hexToRgb(this.backgroundColor);
      this.elementRef.nativeElement.setAttribute('style', 'background-color:  rgba(' + colorComponents.r + ',' + colorComponents.g  + ',' + colorComponents.b + ',' + this.opacity + ');');
    });
  }

}
