import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ColorComponents, Utilities} from '../Utilities';

@Directive({
    selector: '[appInvertTextColor]',
    standalone: false
})
export class InvertTextColorDirective implements AfterViewInit {
  @Input() useComputedStyles = false;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  private readonly element: any;
  private backgroundColor = '';
  ngAfterViewInit(): void {
    this.updateColor();
  }

  @HostListener('style.background-color', ['$event'])
  onBackgroundColorChange() {
    this.updateColor();
  }

  private getComputedElementStyles() {
    const styles = window.getComputedStyle(this.element);
    const backgroundColor = styles.backgroundColor;
    if (backgroundColor) {
      this.backgroundColor = backgroundColor;
    }
  }

  private getElementStyles() {
    const styles = this.element.getAttribute('style');
    const styleAttributes = styles?.split(';') ?? [];
    styleAttributes.forEach((attr: string) => {
      const attrArray = attr.split(':');
      if (attrArray && attrArray.length === 2 && attrArray[0].trim().toLowerCase() === 'background-color') {
        this.backgroundColor = attrArray[1].trim();
      }
    });
  }

  private updateColor() {
    if (this.useComputedStyles) {
      this.getComputedElementStyles();
    } else {
      this.getElementStyles();
    }
    if (this.backgroundColor.length === 0) { return; }
    const backgroundColorComponents = this.colorStringToColorComponents(this.backgroundColor);
    const textColor = this.getCssColor(backgroundColorComponents);
    this.element.setAttribute('style', 'color:' + textColor + '; background-color: ' + this.backgroundColor + ';');
  }

  private colorStringToColorComponents(colourString: string): ColorComponents {
    if (colourString && colourString.indexOf('#') === 0) { return  Utilities.hexToRgb(colourString); }
    if (colourString && colourString.indexOf('rgb') === 0) { return Utilities.stringToRgb(colourString); }
    return {r: 255, b: 255, g: 255, a: 1 };
  }

  private getCssColor(backgroundColorComponents: ColorComponents): string {
    const red = backgroundColorComponents.r;
    const green = backgroundColorComponents.g;
    const blue = backgroundColorComponents.b;
    // Convert RGB to HSL
    // The R,G,B values are divided by 255 to change the range from 0..255 to 0..1
    const redPrime = red / 255;
    const greenPrime = green / 255;
    const bluePrime = blue / 255;
    // Then we extract max and min values
    const colorMax = Math.max(redPrime, greenPrime, bluePrime);
    const colorMin = Math.min(redPrime, greenPrime, bluePrime);
    /*
    HSL is Hue, saturation and lightness.
    We need only lightness to determine if the color is bright or dark.
    So we are going to calculate only the last value with formula: L = (Cmax + Cmin) / 2
    */
    const lightness = (colorMax + colorMin) / 2;
    /*
    Now we are going to check if our lightness is >50% or < 50%.
    If it is >50% we are going to change text color to black
    otherwise, we are going to set text color to white.
    */
    return lightness >= 0.50 ? 'black' : 'white';
  }
}
