import {AfterViewInit, Directive, ElementRef, Host, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appTextareaJumptext]',
    standalone: false
})
export class TextareaJumptextDirective {

  @Input() jumpTextTarget = '#';

  private initPos = 0;

  private static regexIndexOf(str: string, pattern: RegExp, startIndex: number) {
    startIndex = startIndex || 0;
    const searchResult = str.substr( startIndex ).search( pattern );
    return ( -1 === searchResult ) ? -1 : searchResult + startIndex;
  }

  constructor(private elementRef: ElementRef) { }

  @HostListener('keydown', ['$event'])
  keydownHandler($event): void {
    const textSize = this.elementRef.nativeElement.value.length;
    const key = $event.key;
    if (key !== 'Tab') {
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();
    let nextSpacePos;
    const currentPos = ($event.shiftKey)
      ? this.elementRef.nativeElement.value.lastIndexOf(this.jumpTextTarget, this.initPos - 2)
      : this.elementRef.nativeElement.value.indexOf(this.jumpTextTarget, this.initPos);
    if (this.elementRef.nativeElement.value.charAt(currentPos + 1) === '(') {
      nextSpacePos = this.elementRef.nativeElement.value.indexOf(')', currentPos) + 1;
    } else if (this.elementRef.nativeElement.value.charAt(currentPos + 1) === '[') {
      nextSpacePos = this.elementRef.nativeElement.value.indexOf(']', currentPos) + 1;
    } else if (this.elementRef.nativeElement.value.charAt(currentPos + 1) === '{') {
      nextSpacePos = this.elementRef.nativeElement.value.indexOf('}', currentPos) + 1;
    } else {
      nextSpacePos = TextareaJumptextDirective.regexIndexOf(this.elementRef.nativeElement.value, /\s/, currentPos);
    }
    if (currentPos === -1) {
      this.initPos = 0;
      this.elementRef.nativeElement.focus();
    } else {
      if (currentPos === textSize - 1) {
        this.selectRange(this.elementRef.nativeElement.value, textSize - 1, textSize);
      } else {
        this.selectRange(this.elementRef.nativeElement.value, currentPos, nextSpacePos);
      }
      this.initPos = currentPos + 1;
    }
  }

  private selectRange(str: string, start: number, end: number) {
    if (!end) { end = start; }
    return [...str].forEach(() => {
      if (this.elementRef.nativeElement.setSelectionRange) {
        this.elementRef.nativeElement.focus();
        this.elementRef.nativeElement.setSelectionRange(start, end);
      } else if (this.elementRef.nativeElement.createTextRange) {
        const range = this.elementRef.nativeElement.createTextRange();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', start);
        range.select();
      }
    });
  }
}
