import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appTextareaHidpiRenderer]',
    standalone: false
})
export class TextareaHidpiRendererDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.applyStyles();
  }

  private applyStyles() {
    const textarea = this.el.nativeElement;
    if (textarea.tagName.toLowerCase() === 'textarea') {
      this.renderer.setStyle(textarea, 'text-rendering', 'optimizeLegibility');
      this.renderer.setStyle(textarea, '-webkit-font-smoothing', 'antialiased');
      this.renderer.setStyle(textarea, '-moz-osx-font-smoothing', 'grayscale');
    }
  }
}
