import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {EmailTemplateCollectionResponse} from './entities/email-template-collection-response';
import {EmailTemplateItemResponse} from './entities/email-template-item-response';
import {EmailTemplate} from './entities/email-template-entity';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {AdminApiService} from '../base/repository/admin-api.service';

@Injectable({
    providedIn: 'root'
})
export class EmailTemplateRepositoryService {

    constructor(private api: ApiService, private adminApi: AdminApiService) {
    }

    getEmailTemplates(): Observable<EmailTemplate[]> {
      return this.api.get<EmailTemplateCollectionResponse>('/emails/templates')
        .pipe(map(response => response.items));
    }

    loadEmailTemplate(id: number): Observable<EmailTemplate> {
      return this.api.get<EmailTemplateItemResponse>('/emails/templates/' + id)
        .pipe(map(response => response.value));
    }

    createEmailTemplate(template: EmailTemplate): Observable<number> {
      return this.api.post<ItemCreatedResponse>('/emails/templates', template)
        .pipe(map(response => response.value));
    }

    updateEmailTemplate(template: EmailTemplate): Observable<boolean> {
      return this.api.put<ItemUpdatedResponse>('/emails/templates/' + template.id, template)
        .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
    }


  adminLoadEmailTemplate(id: number): Observable<EmailTemplate> {
    return this.adminApi.get<EmailTemplateItemResponse>('/emailtemplates/' + id)
      .pipe(map(response => response.value));
  }

  adminCreateEmailTemplate(template: EmailTemplate): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/emailtemplates', template)
      .pipe(map(response => response.value));
  }

  adminUpdateEmailTemplate(template: EmailTemplate): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/emailtemplates/' + template.id, template)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }
}
