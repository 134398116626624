import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appTextareaAutosize]',
    standalone: false
})
export class TextareaAutosizeDirective implements AfterViewInit {


  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const scrollHeight = this.elementRef.nativeElement.scrollHeight;
      const currentStyle = this.elementRef.nativeElement.getAttribute('style');
      this.elementRef.nativeElement.setAttribute('style', currentStyle + ' height:' + (scrollHeight + 20) + 'px;');
    });
  }
}
