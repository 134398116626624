import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Draft, DraftStatus} from 'src/app/modules/patient-drafts-repository/entities/draft';
import {User} from '../../modules/base/entities/user';

@Injectable({
    providedIn: 'root'
})
export class DraftsService implements OnDestroy {

    private draftsBehaviourSubject: BehaviorSubject<DraftEvent> = new BehaviorSubject<DraftEvent>(new DraftEvent('draft_service_initialized'));
    private drafts: PatientDraft[] = [];
    private repositorySubscription: Subscription;
    events$ = this.draftsBehaviourSubject.asObservable();

    constructor() {
    }

    ngOnDestroy() {
      if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
    }

    clearDrafts() {
      this.drafts = [];
      this.draftsBehaviourSubject.next(new DraftEvent('drafts_cleared'));
    }

    triggerEvent(type: DraftEventType) {
      this.draftsBehaviourSubject.next(new DraftEvent(type));
    }

    addNewDraft(patientId: number, profileId: number, practiceId: number, draftType: string, draftId: number, draftTitle: string, draftStatus: DraftStatus = DraftStatus.OPEN) {
        this.minimizeAllDrafts();
        const nextIndex = this.drafts.length;
        const draft = new PatientDraft(nextIndex, patientId, profileId, practiceId, draftType, draftId, draftTitle, draftStatus);
        this.drafts.push(draft);
        this.draftsBehaviourSubject.next(new DraftEvent('draft_added'));
    }

    openExistingDraft(draft: Draft) {
      this.minimizeAllDrafts();
      const existingDraftIndex = this.drafts.findIndex(d => d.draftId === draft.draftId);
      if (existingDraftIndex > -1) {
        this.drafts[existingDraftIndex].draftStatus = DraftStatus.OPEN;
      } else {
        const nextIndex = this.drafts.length;
        const patientDraft = new PatientDraft(nextIndex, draft.patientId, draft.profileId, draft.practiceId, draft.draftType, draft.draftId, draft.draftTitle, DraftStatus.OPEN);
        this.drafts.push(patientDraft);
      }
      this.draftsBehaviourSubject.next(new DraftEvent('draft_opened'));
    }

    minimizeAllDrafts() {
        for (const draft of this.drafts) {
          draft.draftStatus = DraftStatus.MINIMIZED;
        }
        this.draftsBehaviourSubject.next(new DraftEvent('draft_updated'));
    }

    minimizeDraft(draftIndex: number) {
        const index = this.drafts.findIndex(d => d.index === draftIndex);
        this.drafts[index].draftStatus = DraftStatus.MINIMIZED;
        this.draftsBehaviourSubject.next(new DraftEvent('draft_updated'));
    }

    maximizeDraft(draftIndex: number) {
        this.minimizeAllDrafts();
        const index = this.drafts.findIndex(d => d.index === draftIndex);
        this.drafts[index].draftStatus = DraftStatus.MAXIMIZED;
        this.draftsBehaviourSubject.next(new DraftEvent('draft_updated'));
    }

    restoreDraft(draftIndex: number) {
        this.minimizeAllDrafts();
        const index = this.drafts.findIndex(d => d.index === draftIndex);
        this.drafts[index].draftStatus = DraftStatus.OPEN;
        this.draftsBehaviourSubject.next(new DraftEvent('draft_updated'));
    }

    closeDraft(draftIndex: number) {
        this.minimizeAllDrafts();
        const index = this.drafts.findIndex(d => d.index === draftIndex);
        this.drafts.splice(index, 1);
        this.draftsBehaviourSubject.next(new DraftEvent('draft_updated'));
    }

    getDrafts(): PatientDraft[] {
        return this.drafts;
    }

    hasDrafts(): boolean {
        return this.drafts.length > 0;
    }

    hasMinimizedDrafts(): boolean {
      return this.drafts.filter(d => d.draftStatus === DraftStatus.MINIMIZED).length > 0;
    }
}


export class PatientDraft implements Draft {
  index: number;
  patientId: number;
  practiceId: number;
  profileId: number;
  draftType: string;
  draftId: number;
  draftTitle: string;
  draftStatus: DraftStatus;
  createdDate: Date;
  modifiedDate: Date;
  createdUser: User;
  lastModifiedUser: User;


  constructor(index: number, patientId: number, profileId: number, practiceId: number, draftType: string, draftId: number, draftTitle: string, draftStatus: DraftStatus) {
    this.index = index;
    this.patientId = patientId;
    this.profileId = profileId;
    this.practiceId = practiceId;
    this.draftType = draftType;
    this.draftId = draftId;
    this.draftTitle = draftTitle;
    this.draftStatus = draftStatus;
    this.createdDate = new Date();
    this.modifiedDate = new Date();
  }
}

export type DraftEventType =
  | 'draft_service_initialized'
  | 'drafts_loaded'
  | 'drafts_cleared'
  | 'draft_added'
  | 'draft_removed'
  | 'draft_updated'
  | 'draft_opened';

export class DraftEvent {
  constructor(readonly type: DraftEventType, readonly draft?: PatientDraft) { }
}
