import { Pipe, PipeTransform } from '@angular/core';
import { MessageStatus } from 'src/app/modules/patient-sms-repository/entities/message-status';

@Pipe({
    name: 'messageStatusFormatter',
    standalone: false
})
export class MessageStatusFormatterPipe implements PipeTransform {
    transform(status: MessageStatus): string {
        switch (status) {
            case MessageStatus.SENT:
                return 'Sent Successfully';
            case MessageStatus.DRAFT:
                return 'Draft';
            case MessageStatus.ERROR:
                return 'Failed to send';
            case MessageStatus.SENDING:
                return 'Message sending';
            default:
                return 'An unknown status was returned';
        }
    }
}
