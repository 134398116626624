import { Component, OnInit, Input } from '@angular/core';
import { ContactNumber } from 'src/app/modules/patient-repository/entities/patient';

@Component({
    selector: 'app-mat-fax-card',
    templateUrl: './app-mat-fax-card.component.html',
    styleUrls: ['./app-mat-fax-card.component.scss'],
    standalone: false
})
export class AppMatFaxCardComponent implements OnInit {

    @Input() number: string

    constructor() { }

    ngOnInit(): void {
    }

    get faxNumber(): string {
        return (this.number && this.number.length) ? this.number: 'Not specified';
    }

}
