import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UserRepositoryService} from '../../../modules/user-repository/user-repository.service';
import {AuthenticationService} from '../../../core/auth/authentication.service';

@Component({
    selector: 'app-lock',
    templateUrl: './app-lock.component.html',
    styleUrls: ['./app-lock.component.scss'],
    standalone: false
})
export class AppLockComponent implements OnInit {

  @Output() login: EventEmitter<boolean> = new EventEmitter();
  @Output() logout: EventEmitter<boolean> = new EventEmitter();

  password = '';
  passwordFieldType = 'password';
  errorMessage = '';
  validated = false;

  get fieldValid(): boolean {
    return this.password.length > 0 && this.errorMessage === '';
  }

  get timeoutDuration(): string {
    return (environment.idleTimeout / 60 / 1000).toString();
  }

  get emailAddress(): string {
    return this.authService.getEmailAddress();
  }

  get passwordVisible(): boolean {
    return this.passwordFieldType !== 'password';
  }

  constructor(private userRepository: UserRepositoryService, private authService: AuthenticationService) {}

  ngOnInit(): void {
  }

  togglePasswordVisibility() {
    this.passwordFieldType = (this.passwordVisible) ? 'password' : 'text';
  }

  onLogout() {
    this.logout.next(true);
  }

  onClick() {
    this.validated = true;
    this.errorMessage = '';
    this.userRepository.verifyUserCredentials(this.password).subscribe(result => {
      if (result) {
        this.login.next(true);
      } else {
        this.errorMessage = 'Could not verify user credentials';
      }
    }, error => {
      console.error(error);
      this.errorMessage = 'Could not verify user credentials';
    });

  }

}
