import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseTag} from 'src/app/modules/base/entities/base-tag';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-tag-search',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    standalone: false
})
export class TagComponent implements OnInit, OnDestroy {
  @Input() tags: BaseTag[] = [];
  @Input() searchObservable: (query: string) => Observable<any[]> = null;
  @Output() tagsChange = new EventEmitter<BaseTag[]>();

  formControl = new UntypedFormControl();
  searchResults: BaseTag[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  scrollStrategy: ScrollStrategy = this.scrollStrategyOptions.block();

  constructor(private scrollStrategyOptions: ScrollStrategyOptions) {
  }

  get hasResults(): boolean {
    return this.searchResults.length > 0;
  }

  ngOnInit(): void {
    if (this.searchObservable === null) {
      throw Error('SearchObservable is a required input');
    }
  }

  ngOnDestroy(): void {
  }

  selectItem(tag: BaseTag) {
    this.addTag(tag);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our Tag
    if (value) {
      const newTag = {
        id: 0,
        tag: value,
        hexColor: '#ffffff'
      } as BaseTag;
      this.addTag(newTag);
    }
    // Clear the input value
    event.chipInput?.clear();
  }

  remove(tag: BaseTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.tagsChange.next(this.tags);
    }
  }

  onSearchResults(results: BaseTag[]) {
    if (results === null) {
      results = [];
    }
    this.searchResults = results;
  }

  private addTag(tag: BaseTag) {
    this.tags.push(tag);
    this.tagsChange.next(this.tags);
    this.formControl.setValue('');
  }
}
