import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'textSanitize',
    standalone: false
})
export class TextSanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    if (!value) {
      value = '';
    }
    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }

}
