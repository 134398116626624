import {Injectable} from '@angular/core';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {Observable} from 'rxjs';
import {PatientStatus} from '../patient-repository/entities/patient-status';
import {PatientStatusCollectionResponse} from '../patient-repository/entities/patient-status-collection-response';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {PatientFlagEntity} from './entities/patient-flag-entity';


@Injectable({
  providedIn: 'root'
})
export class PatientListsRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  loadLists(): Observable<PatientFlagEntity[]> {
    return this.api.get<PatientStatusCollectionResponse>('/patients/flags')
      .pipe(map(response => response.items));
  }

  adminLoadLists(): Observable<PatientFlagEntity[]> {
    return this.adminApi.get<PatientStatusCollectionResponse>('/patients/flags')
      .pipe(map(response => response.items));
  }

  adminCreate(status: PatientFlagEntity): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/patients/flags', status)
      .pipe(map(response => response.value));
  }

  adminUpdate(id: number, status: PatientFlagEntity): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/patients/flags/' + id, status)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDelete(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/patients/flags/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
