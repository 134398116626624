import {Component, ElementRef, HostBinding, inject, input, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {AngularEditorConfig, UploadResponse} from '@kolkov/angular-editor';
import {from, Observable, Subject, Subscription, throwError} from 'rxjs';
import {HttpEvent} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, NgControl, UntypedFormBuilder} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-basic-editor', standalone: false,

  templateUrl: './basic-editor.component.html', styleUrl: './basic-editor.component.scss'
})
export class BasicEditorComponent implements OnInit, OnDestroy {

  static maxImageSize = 350 * 1024;
  @Input() content = '';
  @Input() debug = false;
  config: AngularEditorConfig = {
    editable: true,
    minHeight: '10em',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    toolbarPosition: 'top',
    upload: this.convertFileToBase64.bind(this),
    toolbarHiddenButtons: [['italic', 'underline', 'strikeThrough', 'undo', 'redo', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'], ['backgroundColor', 'customClasses', 'insertVideo', 'insertHorizontalRule', 'toggleEditorMode']]
  };

  @Output() contentChange = new Subject<string>();
  private formBuilder = inject(UntypedFormBuilder);
  private subscription: Subscription;
  contentInput = this.formBuilder.group({
    content: ['']
  });

  get value(): string {
    return this.contentInput.value.content;
  }

  ngOnInit(): void {
    this.contentInput.setValue({content: this.content});
    this.subscription = this.contentInput.valueChanges.subscribe(value => {
      this.contentChange.next(value.content);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private convertFileToBase64(file: File): Observable<HttpEvent<UploadResponse>> {
    if (file.size > BasicEditorComponent.maxImageSize) {
      return throwError(new Error('File size exceeds 350KB'));
    }
    return from(this.convertToBase64(file)).pipe(map(base64 => {
      const response: UploadResponse = {imageUrl: base64};
      return {type: 0, body: response} as HttpEvent<UploadResponse>;
    }));
  }

  private convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }
}
