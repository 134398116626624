<div class="parent" *ngIf="active">
    <div class="modal fade" tabindex="-1" [class.show] = "active && visible" [class.d-block] = "active && visible">
        <div class="modal-dialog mt-9">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{disabledTitle}}</h5>
                </div>
                <div class="modal-body">
                    <p>{{disabledMessage}}</p>
                </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="onClick()">Ok</button>
                    </div>
                </div>
        </div>
    </div>
    <div class="modal-backdrop fade" [class.show] = "active && visible" style="z-index: 1045;"></div>
</div>