export type EventType =
| 'visible'
| 'hidden'
| 'token_expired'
| 'token_received'
| 'token_refreshed'
| 'token_error'
| 'system_error'
| 'login'
| 'logout';

export class AuthEvent {
  constructor(readonly type: EventType) {
  }
}
