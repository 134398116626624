import {Injectable} from '@angular/core';
import {Patient, QueryPatient} from './entities/patient';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PatientItemResponse} from './entities/patient-item-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {PatientQueryResponse} from './entities/patient-query-response';
import {PatientCreatedResponse} from './entities/patient-created-response';
import {PatientStatus} from './entities/patient-status';
import {PatientStatusCollectionResponse} from './entities/patient-status-collection-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {SimilarPatientsCollectionResponse} from './entities/similar-patients-collection-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {CreatedPatient} from './entities/CreatedPatient';
import {PracticeList} from '../practice-settings-repository/entities/practice-list';
import {PracticePatientFlag} from './entities/practice-patient-flag';
import {PracticePatientFlagCollectionResponse} from './entities/practice-patient-flag-collection-response';

@Injectable({
  providedIn: 'root'
})
export class PatientRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  query(query: string): Observable<QueryPatient[]> {
    return this.api.get<PatientQueryResponse>('/patients/search/', {query: query})
      .pipe(map(response => response.items));
  }

  findSimilar(query: string): Observable<QueryPatient[]> {
    return this.api.get<SimilarPatientsCollectionResponse>('/patients/similar/', {query: query})
      .pipe(map(response => response.items));
  }

  undelete(id: number): Observable<boolean> {
    return this.api.delete<ItemDeletedResponse>('/patients/' + id + '/undo')
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete<ItemDeletedResponse>('/patients/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadByProfileId(profileId: number): Observable<Patient> {
    return this.api.get<PatientItemResponse>('/patients/' + profileId)
      .pipe(map(response => response.value));
  }

  calendarCreate(patient: Patient): Observable<PatientCreatedResponse> {
    return this.api.post<PatientCreatedResponse>('/patients/calendar/', patient)
      .pipe(map(response => response));
  }

  create(patient: Patient): Observable<CreatedPatient> {
    return this.api.post<PatientCreatedResponse>('/patients/', patient)
      .pipe(map(response => response.value));
  }

  update(id: number, patient: Patient): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/patients/' + id, patient)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  flag(id: number): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('/patients/' + id + '/flag')
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  unFlag(id: number): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('/patients/' + id + '/unflag')
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadFlaggedPatients(): Observable<QueryPatient[]> {
    return this.api.get<PatientQueryResponse>('/patients/flagged/')
      .pipe(map(response => response.items));
  }

  loadPatientsForList(listId: number): Observable<PracticePatientFlag[]> {
    return this.api.get<PracticePatientFlagCollectionResponse>('/patients/flagged/' + listId)
      .pipe(map(response => response.items));
  }

  loadStatuses(): Observable<PatientStatus[]> {
    return this.api.get<PatientStatusCollectionResponse>('/patients/statuses')
      .pipe(map(response => response.items));
  }

  updateFlaggedComment(id: number, comment: string): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/patients/' + id + '/flagged-comment', {flaggedComment: comment})
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminLoadStatuses(): Observable<PatientStatus[]> {
    return this.adminApi.get<PatientStatusCollectionResponse>('/patientstatus')
      .pipe(map(response => response.items));
  }

  adminCreate(status: PatientStatus): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/patientstatus', status)
      .pipe(map(response => response.value));
  }

  adminUpdate(id: number, status: PatientStatus): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/patientstatus/' + id, status)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDelete(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/patientstatus/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  updatePatientFlags(patientId: number, flagIds: number[]): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/patients/' + patientId + '/flags', {flagIds: flagIds})
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  addFlagToPatient(patientId: number, flagId: number): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('/patients/' + patientId + '/flags/' + flagId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  removeFlagFromPatient(patientId: number, flagId: number): Observable<boolean> {
    return this.api.delete<ItemUpdatedResponse>('/patients/' + patientId + '/flags/' + flagId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  updatePatientFlag(flag: PracticePatientFlag): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/patients/flags/' + flag.id, flag)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadFlagsForPatient(patientId: number): Observable<PracticePatientFlag[]> {
    return this.api.get<PracticePatientFlagCollectionResponse>('/patients/' + patientId + '/flags')
      .pipe(map(response => response.items));
  }
}
