import { Pipe, PipeTransform } from '@angular/core';
import { EmailContact } from 'src/app/modules/patient-emails-repository/entities/email';

@Pipe({
    name: 'emailContactArrayFormatter',
    standalone: false
})
export class EmailContactArrayFormatterPipe implements PipeTransform {
    transform(contacts: EmailContact[]): string {
        if (contacts.length === 0) {
            return 'Unknown Email';
        }
        const firstContact = contacts[0];
        return firstContact.name.length ? firstContact.name : firstContact.address;
    }
}
