import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: false
})
export class DialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) private data: DialogData) {
    this.confirmTitle = data.title ?? 'Please Confirm';
    this.confirmMessage = data.message ?? 'Are you sure?';
  }

  confirmTitle: string;
  confirmMessage: string;

  ngOnInit(): void {
  }

  onClick(action: boolean) {
    this.dialogRef.close(action);
  }
}

export interface DialogData {
  title: string;
  message: string;
}
