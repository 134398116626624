import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appMatCheckboxBackgroundColour]',
    standalone: false
})
export class MatCheckboxBackgroundColourDirective implements OnInit {

  @Input('appMatCheckboxBackgroundColour') backgroundColor: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty('--mdc-checkbox-selected-icon-color', this.backgroundColor);
    this.el.nativeElement.style.setProperty('--mdc-checkbox-unselected-icon-color', this.backgroundColor);
    this.el.nativeElement.style.setProperty('--mdc-checkbox-selected-focus-icon-color', this.backgroundColor);
    this.el.nativeElement.style.setProperty('--mdc-checkbox-unselected-focus-icon-color', this.backgroundColor);
    this.el.nativeElement.style.setProperty('--mdc-checkbox-selected-hover-icon-color', this.backgroundColor);
    this.el.nativeElement.style.setProperty('--mdc-checkbox-unselected-hover-icon-color', this.backgroundColor);
  }

}
