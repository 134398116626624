import {ComponentFactoryResolver, Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {CoreService} from '../../core/services/core.service';
import {stringify} from 'querystring';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CoreEventsService} from '../../core/services/core-events.service';

@Directive({
    selector: '[appPermissionRequired]',
    standalone: false
})
export class PermissionRequiredDirective<T = unknown> implements OnInit, OnDestroy {
  private _appPermissionRequired: string;
  private _appPermissionRequiredElse: TemplateRef<any>|null = null;
  private _appPermissionRequiredCondition: boolean|null = null;
  private coreEventsSubscription: Subscription;
  private conditionSubscription: Subscription;

  @Input() set appPermissionRequired(permission: string) {
    this._appPermissionRequired = permission;
    this.updateView();
  }
  @Input() set appPermissionRequiredElse(elseRef: TemplateRef<any>|null) {
    this._appPermissionRequiredElse = elseRef === null ? null : elseRef;
    this.updateView();
  }
  @Input() set appPermissionRequiredCondition(condition: boolean|null|Observable<boolean>) {
    this.conditionSubscription?.unsubscribe();
    if (condition instanceof Observable) {
      this.conditionSubscription = condition.subscribe(value => {
        this._appPermissionRequiredCondition = value;
        this.updateView();
      });
    } else {
      this._appPermissionRequiredCondition = condition === null ? null : condition;
      this.updateView();
    }
  }

  constructor(private coreService: CoreService, private coreEventsService: CoreEventsService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnDestroy(): void {
    if (this.coreEventsSubscription) { this.coreEventsSubscription.unsubscribe(); }
  }

  ngOnInit(): void {
    if (!this.coreService.hasPermissions) {
      this.coreEventsSubscription = this.coreEventsService.events$.pipe(filter(event => event.type === 'permissions_loaded')).subscribe(() => {
        this.updateView();
      });
    }
  }

  private updateView(): void {
    this.viewContainer.clear();
    if (this.coreService.hasPermission(this._appPermissionRequired)) {
      if (this._appPermissionRequiredCondition !== null && this._appPermissionRequiredCondition || this._appPermissionRequiredCondition === null) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (this._appPermissionRequiredElse) {
        if (this._appPermissionRequiredCondition !== null && this._appPermissionRequiredCondition || this._appPermissionRequiredCondition === null) {
          this.viewContainer.createEmbeddedView(this._appPermissionRequiredElse);
        }
      }
    }
  }

}
