import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { DraftsService } from 'src/app/core/services/drafts.service';
import { AppTabsService } from 'src/app/core/services/tabs.service';
import { ApplicationConfig } from 'src/app/modules/base/entities/application-config';

@Component({
    selector: 'expanded-fab',
    templateUrl: './expanded-fab.component.html',
    styleUrls: ['./expanded-fab.component.scss'],
    standalone: false
})
export class ExpandedFABComponent implements OnInit {

    @Input() label = 'Create';
    @Output() fabClicked = new EventEmitter();
    private applicationConfig: ApplicationConfig;

    constructor(private coreService: CoreService, private tabService: AppTabsService, private draftsService: DraftsService) { }

    ngOnInit(): void {
        this.applicationConfig = this.coreService.configuration;
    }

    onClick() {
        this.fabClicked.emit();
    }

    get enabled(): boolean {
        return !this.applicationConfig.readonly;
    }

    get hasTabs(): boolean {
        return this.tabService.hasTabs();
    }

    get hasOpenDrafts(): boolean {
        return this.draftsService.hasMinimizedDrafts();
    }

}
