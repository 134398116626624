import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'appTimeDuration',
    standalone: false
})
export class TimeDurationPipe  implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return '00:00';
    }
    const minutes = Math.floor(value / 60);
    const seconds = value - (minutes * 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`;
  }
}
