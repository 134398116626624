import {Injectable} from '@angular/core';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {authConfig} from './auth.config';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {SessionStorageService} from '../../modules/storage/session-storage.service';
import {User} from '../../modules/base/entities/user';
import {AuthEvent, EventType} from './auth-event';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private oauthService: OAuthService, private sessionStorage: SessionStorageService) {
    this.events$ = this.eventsSubject.asObservable();
    this.isAuthenticatedFlag = this.sessionStorage.getItem('user_authenticated', false);
    this.setupOauthService();
    this.setupRefreshTimer();
  }
  events$: Observable<AuthEvent>;
  protected eventsSubject: Subject<AuthEvent> = new Subject<AuthEvent>();
  protected oauthEventsSubscription: Subscription;
  private isAuthenticatedFlag: boolean;
  private timerSubscription: Subscription;

  static uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise triple-equals
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  triggerEvent(event: AuthEvent) {
    this.eventsSubject.next(event);
  }

  connectivityChanged(online: boolean) {
    if (online) {
      this.refreshToken();
      this.setupRefreshTimer();
      this.oauthService.sessionChecksEnabled = true;
    } else {
      this.oauthService.sessionChecksEnabled = false;
      this.stopRefreshTimer();
    }
  }

  appFocusChanged(eventType: EventType) {
    if (eventType === 'visible') {
      const expiration = this.oauthService.getAccessTokenExpiration();
      const timeout = this.calculateTimeout(expiration);
      if (timeout === 0) {
        this.refreshToken();
      }
    }
  }

  getAccountUrl(): string {
    return this.oauthService.issuer + '/account';
  }

  getFullName(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['name'];
  }

  getEmailAddress(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['email'];
  }

  getFirstName(): string {
    return this.getUserName();
  }

  getUserName(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['given_name'];
  }

  getSurname(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['family_name'];
  }

  getUserId() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['sub'];
  }

  getUser(): User {
    return {
      email: this.getEmailAddress(),
      firstName: this.getUserName(),
      surname: this.getSurname(),
      userId: this.getUserId(),
      name: this.getFullName()
    };
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedFlag;
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    this.oauthService.logOut();
  }

  refreshToken() {
    if (this.oauthService.tokenEndpoint === null) {
      console.error('token endpoint not found');
      return;
    }
    if (this.oauthService.getRefreshToken() === null) {
      console.error('refresh not found');
      return;
    }
    this.oauthService.refreshToken();
  }

  private setupOauthService() {
    this.oauthEventsSubscription = this.oauthService.events.subscribe((event) => this.oauthEventsHandler(event));
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin().catch(error => {
      console.error(error);
      this.handleLogout();
    });
  }

  private setupRefreshTimer() {
    this.stopRefreshTimer();
    const expiration = this.oauthService.getAccessTokenExpiration();
    const timeout = this.calculateTimeout(expiration);
    this.timerSubscription = of(new AuthEvent('token_expired')).pipe(delay(timeout)).subscribe(() => {
      this.refreshToken();
    });
  }

  private stopRefreshTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private handleLogout() {
    this.isAuthenticatedFlag = false;
    this.sessionStorage.setItem('user_authenticated', this.isAuthenticatedFlag);
  }

  private handleLogin() {
    const previousState = this.isAuthenticatedFlag;
    this.setupRefreshTimer();
    this.isAuthenticatedFlag = true;
    if (!previousState && this.isAuthenticatedFlag) {
      // delay to allow the UI to finish updating prior to emitting the event
      setTimeout(() => {
        this.eventsSubject.next(new AuthEvent('login'));
      }, 10);
    }
    this.sessionStorage.setItem('user_authenticated', this.isAuthenticatedFlag);
  }

  private oauthEventsHandler(event: OAuthEvent) {
    if (
      event.type === 'logout' ||
      event.type === 'token_refresh_error' ||
      event.type === 'discovery_document_load_error' ||
      event.type === 'discovery_document_validation_error') {
      this.handleLogout();
    }
    if (event.type === 'token_received') {
      this.handleLogin();
    }
  }

  private calculateTimeout(expiration: number): number {
    const now = Date.now();
    const timeoutFactor = this.oauthService.timeoutFactor;
    const delta = (expiration - now) * timeoutFactor;
    return Math.max(0, delta);
  }
}

