import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';

@Directive({
    selector: '[appSearchBox]',
    standalone: false
})
export class SearchBoxDirective implements OnInit, OnDestroy {

  private debounceTimeOut = 500;
  private keyupEventSubscription: Subscription;
  private queryTextChangedSubscription: Subscription;
  private searchResultsQuerySubscription: Subscription;
  private searchStatusChangeSubscription: Subscription;
  private isSearching = new BehaviorSubject<boolean>(false);
  private keyupEvent = new Subject<KeyboardEvent>();

  @Input() minCharacters = 3;
  @Input() searchObservable: (query: string) => Observable<any[]> = null;
  @Input() formControl: UntypedFormControl = null;
  @Output() results = new EventEmitter<any[]>();
  @Output() error = new EventEmitter<string>();
  @Output() searchStatusChange = new EventEmitter<boolean>();


  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  get queryText(): string {
    return this.formControl.value.trim();
  }

  ngOnInit(): void {
    if (this.searchObservable === null) { throw Error('SearchObservable is a required input'); }
    if (this.formControl === null) { throw Error('Element FormControl is a required input'); }
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
    this.renderer.setAttribute(this.el.nativeElement, 'autocapitalize', 'none');
    this.renderer.setAttribute(this.el.nativeElement, 'autocorrect', 'off');
    this.renderer.listen(this.el.nativeElement, 'keyup', (event: KeyboardEvent) =>  this.keyupEvent.next(event));
    this.keyupEventSubscription = this.keyupEvent
      .subscribe(event => this.keyupHandler(event));
    this.queryTextChangedSubscription = this.formControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(this.debounceTimeOut))
      .subscribe(() => this.search());
    this.searchStatusChangeSubscription = this.isSearching
      .subscribe(status => this.searchStatusChange.next(status));
  }

  ngOnDestroy(): void {
    this.stopSearchAndRemoveResults();
    this.searchStatusChangeSubscription?.unsubscribe();
    this.searchResultsQuerySubscription?.unsubscribe();
    this.queryTextChangedSubscription?.unsubscribe();
    this.keyupEventSubscription?.unsubscribe();
    this.keyupEvent.unsubscribe();
  }

  keyupHandler($event: KeyboardEvent): void {
    if ($event.key === 'enter') {
      this.search(true);
    }
    if ($event.key === 'esc') {
      this.cancelSearch();
    }
    if ($event.key === 'backspace') {
      this.backspace();
    }
  }

  search(force: boolean = false) {
    if (this.queryText.length === 0) { this.cancelSearch(); }
    if ((this.queryText.length < this.minCharacters && !force) || this.isSearching.getValue()) { return; }
    this.isSearching.next(true);
    this.searchResultsQuerySubscription?.unsubscribe();
    this.searchResultsQuerySubscription = this.searchObservable(this.queryText)
      .subscribe(results => {
      if (results === null) { results = []; }
      this.searchResultsQuerySubscription?.unsubscribe();
      this.results.emit(results);
      this.isSearching.next(false);
    }, error => {
      this.searchResultsQuerySubscription?.unsubscribe();
      this.error.emit(error);
      this.isSearching.next(false);
    });
  }

  backspace() {
    if (this.queryText.length === 0) {
      this.stopSearchAndRemoveResults();
    }
  }

  cancelSearch() {
    this.stopSearchAndRemoveResults();
  }

  private stopSearchAndRemoveResults() {
    this.searchResultsQuerySubscription?.unsubscribe();
    this.el.nativeElement.value = '';
    this.results.emit(null);
    this.isSearching.next(false);
  }
}
