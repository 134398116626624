import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: false
})
export class BannerComponent implements OnInit {

  @Input() bgColorClass = 'dark';
  @Input() message = '';
  @Input() primaryActionText = '';
  @Input() secondaryActionText = '';
  @Input() useOverlay = false;
  @Output() primaryAction = new EventEmitter<void>();
  @Output() secondaryAction = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onPrimaryAction() {
    this.primaryAction.next();
  }

  onSecondaryAction() {
    this.secondaryAction.next();
  }

}
