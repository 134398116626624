import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.authServer,
  clientId: environment.clientId,
  redirectUri: window.location.origin,
  scope: environment.scope,
  responseType: environment.responseType,
  requireHttps: environment.requireHttps,
  sessionChecksEnabled: environment.sessionChecksEnabled,
  sessionCheckIntervall: environment.sessionCheckInterval,
  showDebugInformation: environment.showDebugInformation,
  timeoutFactor: 0.75,
};
