import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-mat-autocomplete',
    templateUrl: './app-mat-autocomplete.component.html',
    styleUrls: ['./app-mat-autocomplete.component.scss'],
    standalone: false
})
export class AppMatAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {

  autocompleteControl = new UntypedFormControl('');
  errorText = '';
  isSearching = false;
  searchResults: any[] = null;
  scrollStrategy: ScrollStrategy = this.scrollStrategyOptions.block();
  hostWidth = '0';

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() color: ThemePalette = 'primary';
  @Input() placeholder = 'Search';
  @Input() searchObservable: (query: string) => Observable<any[]> = null;
  @Input() addItemCardText = '';
  @Input() displayResultsCardTemplate: TemplateRef<any> = null;
  @Input() alwaysShowAddCard = false;
  @Input() disabled = false;
  @Input() isInvalid = false;
  @Input() compact = false;
  @Output() addNewItem = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @ViewChild('host') hostElement: ElementRef;
  constructor(private scrollStrategyOptions: ScrollStrategyOptions) {
  }

  get offsetY(): number {
    return this.compact ? 16 : 0;
  }

  get queryText(): string {
    return this.autocompleteControl.value;
  }

  get resultsReady(): boolean {
    return !this.isSearching && this.searchResults !== null && this.searchResults.length >= 0 && this.autocompleteControl.value.length > 0;
  }

  get hasError(): boolean {
    return this.errorText.length > 0;
  }

  get isAddNewItemUsed(): boolean {
    return this.addNewItem.observers.length > 0;
  }

  get isItemSelectedUsed(): boolean {
    return this.itemSelected.observers.length > 0;
  }

  ngOnInit(): void {
    if (this.searchObservable === null) {
      throw Error('SearchObservable is a required input');
    }
    if (this.displayResultsCardTemplate === null) {
      throw Error('displayResultsCardTemplate is a required input');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.hostWidth = this.hostElement?.nativeElement?.offsetWidth;
    });
  }

  ngOnDestroy(): void {
  }

  search(value: string) {
    this.autocompleteControl.setValue(value);
  }

  selectItem(result: any) {
    if (!this.isItemSelectedUsed) {
      return;
    }
    this.itemSelected.emit(result);
    this.cancelSearch();
  }

  addItem() {
    if (!this.isAddNewItemUsed) {
      return;
    }
    this.addNewItem.emit(true);
  }

  cancelSearch() {
    this.autocompleteControl.setValue('');
    this.errorText = '';
    this.searchResults = null;
  }

  closeSearchResults(selectedValue: any) {
    this.autocompleteControl.setValue(selectedValue);
    this.errorText = '';
    this.searchResults = null;
  }

  onSearchResults(results: any[]) {
    if (results === null) {
      this.searchResults = [];
    }
    this.searchResults = results;
    this.errorText = '';
  }

  onSearchStatusChange(isSearching: boolean) {
    this.isSearching = isSearching;
  }

  onError(errorMessage: string) {
    this.errorText = errorMessage;
  }
}
