import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TotalStorageUsageResponse} from './entities/total-storage-usage-response';
import {VerifyStorageAvailableResponse} from './entities/verify-storage-available-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {UpdateUpload} from './entities/upload';
import {ApiService} from '../base/repository/api.service';
import {SignedUrlResponse} from '../base/entities/signed-url-response';
import {VerifyStorageAvailableItemResponse} from './entities/verify-storage-available-item-response';
import {TotalStorageUsageItemResponse} from './entities/total-storage-usage-item-response';

@Injectable({
  providedIn: 'root'
})
export class UploadRepositoryService {


  constructor(private api: ApiService) {
  }

  getSignedUrl(file: string, action: string, expires: number): Observable<string> {
    return this.api.get<SignedUrlResponse>('uploads/generate-signed-url', {fileName: file, action: action, expires: expires})
      .pipe(map(response => response.value));
  }

  uploadFile(file: File, url: string): Observable<any> {
    return this.api.uploadFile<any>(url, file, null, {'x-file-name': file.name});
  }

  getTotalStorageUsage(): Observable<TotalStorageUsageResponse> {
    return this.api.get<TotalStorageUsageItemResponse>('uploads/storage/usage')
      .pipe(map(response => response.value));

  }

  verifyStorageAvailable(uploadSize: number): Observable<VerifyStorageAvailableResponse> {
    return this.api.get<VerifyStorageAvailableItemResponse>('uploads/storage/available', {uploadSize: uploadSize})
      .pipe(map(response => response.value));

  }

  updateFile(id: number, upload: UpdateUpload): Observable<boolean> {
    return this.api.put<ItemDeletedResponse>('uploads/' + id, upload)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  deleteFile(id: number): Observable<boolean> {
    return this.api.delete<ItemDeletedResponse>('uploads/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
