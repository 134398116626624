import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {ADMIN_URL} from '../../../app-provider';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService extends ApiService {
  constructor(protected http: HttpClient, @Inject(ADMIN_URL) protected adminUrl: string) {
    super(http, adminUrl);
  }
}
