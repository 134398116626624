import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-mat-textarea',
    templateUrl: './app-mat-text-area.component.html',
    styleUrls: ['./app-mat-text-area.component.scss'],
    standalone: false
})
export class AppMatTextAreaComponent implements OnInit {

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() color: ThemePalette = 'primary';
  @Input() parentForm: UntypedFormGroup;
    @Input() name = '';
    @Input() label = '';
    @Input() placeholder = '';
    @Input() helpText = '';
    @Input() readonly = false;
    @Input() rows = 3;
    @Input() errorMessage = '';
    private element: UntypedFormControl;

    constructor() { }

    ngOnInit(): void {
        this.element = this.parentForm.get(this.name) as UntypedFormControl;
    }

    get fieldValid(): boolean {
        if (!this.required || this.readonly) { return true; }
        return !this.element.invalid && (this.element.dirty || this.element.touched);
    }

    get required(): boolean {
        const validator = (this.element.validator) ? this.element.validator({} as AbstractControl) : null;
        return !!(validator && validator.required);
    }

    get invalid(): boolean {
        return this.element.invalid;
    }
    get shouldValidate(): boolean {
        return this.element.touched || this.element.dirty;
    }

    blur(event: any) {
    }
}
