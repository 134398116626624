<div role="group" [formGroup]="dateInput" class="app-mat-date-time-input flatpickr" #flatpickr="mwlFlatpickr" mwlFlatpickr
     [wrap]="true"
     [dateFormat]="dateFormat"
     [defaultHour]="defaultHour"
     [defaultMinute]="defaultMinute"
     [minuteIncrement]="defaultIncrement"
     [minDate]="minDate"
     [maxDate]="maxDate"
     [enableTime]="enableTime"
     [plugins]="[confirmDatePlugin]"
     [time24hr]="true"
     [locale]="defaultLocale"
     [disableMobile]="true"
     [allowInput]="false"
     [clickOpens]="false"
     [altInput]="false"
     (flatpickrChange)="onDateChange($event)"
     [convertModelValue]="true">
  <input type="text" formControlName="dateTime" data-input (click)="togglePicker()">
</div>
