import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {PracticeSettings} from './entities/practice-settings';
import {Observable} from 'rxjs';
import {PracticeSettingsItemResponse} from './entities/practice-settings-item-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class PracticeSettingsRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  load(): Observable<PracticeSettings> {
    return this.api.get<PracticeSettingsItemResponse>('/practices/settings')
      .pipe(map(response => response.value));
  }

  adminLoad(): Observable<PracticeSettings> {
    return this.adminApi.get<PracticeSettingsItemResponse>('/practicesettings')
      .pipe(map(response => response.value));
  }

  adminUpdate(practiceSettings: PracticeSettings): Observable<boolean> {
    return this.adminApi.post<ItemUpdatedResponse>('/practicesettings', practiceSettings)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
