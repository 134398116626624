import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {pattern, FormatterService} from '../services/formatter.service';

@Directive({
    selector: '[appInputFormatter]',
    standalone: false
})
export class InputFormatterDirective implements AfterViewInit {

  @Input() formatPattern: pattern = 'default';
  constructor(private elementRef: ElementRef, private formatter: FormatterService) { }

  ngAfterViewInit(): void {
    this.format();
  }

  @HostListener('blur', ['$event'])
  blur() {
    this.format();
  }
  @HostListener('keyup', ['$event'])
  keyUpHandler() {
    this.format();
  }

  @HostListener('paste', ['$event'])
  paste() {
    this.format();
  }

  private format() {
    this.elementRef.nativeElement.value = this.formatter.format(this.elementRef.nativeElement.value, this.formatPattern);
  }
}
