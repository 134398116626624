import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ReportTemplateCollectionResponse} from './entities/report-template-collection-response';
import {ReportTemplateItemResponse} from './entities/report-template-item-response';
import {SystemReportTemplate} from '../report-builder/entities/system-report-template';
import {SystemReportTemplateCollectionResponse} from './entities/system-report-template-collection-response';
import {SystemReportTemplateItemResponse} from './entities/system-report-template-item-response';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {PracticeReportTemplate} from './entities/practice-report-template';
import {ReportTemplate} from '../report-builder/entities/reportTemplate';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateRepositoryService {

  constructor(private api: ApiService) {
  }

  getReportTemplates(): Observable<PracticeReportTemplate[]> {
    return this.api.get<ReportTemplateCollectionResponse>('reports/templates')
      .pipe(map(response => response.items));
  }

  loadReportTemplate(id: number): Observable<PracticeReportTemplate> {
    return this.api.get<ReportTemplateItemResponse>('reports/templates/' + id)
      .pipe(map(response => response.value));
  }

  createReportTemplate(template: ReportTemplate): Observable<number> {
    return this.api.post<ItemCreatedResponse>('reports/templates', template)
      .pipe(map(response => response.value));
  }

  updateReportTemplate(template: ReportTemplate): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('reports/templates/' + template.id, template)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }

  getSystemReportTemplatesForPrint(): Observable<SystemReportTemplate[]> {
    return this.api.get<SystemReportTemplateCollectionResponse>('reports/system-templates/print')
      .pipe(map(response => response.items));
  }

  getSystemReportTemplatesForEdit(): Observable<SystemReportTemplate[]> {
    return this.api.get<SystemReportTemplateCollectionResponse>('reports/system-templates/edit')
      .pipe(map(response => response.items));
  }

  loadSystemReportTemplate(id: number): Observable<SystemReportTemplate> {
    return this.api.get<SystemReportTemplateItemResponse>('reports/system-templates/' + id)
      .pipe(map(response => response.value));
  }

  createSystemReportTemplate(template: ReportTemplate): Observable<number> {
    return this.api.post<ItemCreatedResponse>('reports/system-templates', template)
      .pipe(map(response => response.value));
  }

  updateSystemReportTemplate(template: ReportTemplate): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('reports/system-templates/' + template.id, template)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }
}
