import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Toast, ToastService} from '../../../core/services/toast.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.scss'],
    standalone: false
})
export class ToastsComponent implements OnInit, OnDestroy {
  private toastsSubscription: Subscription;
  constructor(private toastService: ToastService, private snackbar: MatSnackBar) {}

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  ngOnInit(): void {
    this.toastsSubscription = this.toastService.toasts$.subscribe(toast => this.showToast(toast));
  }

  ngOnDestroy(): void {
    this.toastsSubscription?.unsubscribe();
  }

  private showToast(toast: Toast) {
    this.snackbar.open(toast.message, 'Dismiss', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: toast.options?.delay ?? 5000,
    });
  }
}
