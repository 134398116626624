import {Inject, Injectable} from '@angular/core';
import {BASE_URL} from '../../../app-provider';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(protected http: HttpClient, @Inject(BASE_URL) public baseUrl: string) {
  }

  private cleanEndpoint(endpoint: string): string {
    return endpoint.replace(/^\/|\/$/g, '');
  }

  private setHeaders(headers: { [key: string]: string }): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    if (headers === null) { return httpHeaders; }
    for (const key in headers) {
      if (headers.hasOwnProperty(key) && headers[key] !== null && headers[key] !== undefined && headers[key].trim() !== '') {
        httpHeaders = httpHeaders.set(key, headers[key]);
      }
    }
    return httpHeaders;
  }

  private setParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    if (params === null) { return httpParams; }
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== undefined && params[key].toString().trim() !== '') {
        httpParams = httpParams.set(key, params[key].toString());
      }
    }
    return httpParams;
  }
  public get<T>(endpoint: string, params?: { [key: string]: any }, headers?: { [key: string]: string }): Observable<T> {
    const httpHeaders = this.setHeaders(headers || {});
    const httpParams = this.setParams(params || {});
    endpoint = this.cleanEndpoint(endpoint);
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`, { headers: httpHeaders, params: httpParams });
  }

  public post<T>(endpoint: string, body?: any, params?: { [key: string]: any }, headers?: { [key: string]: string }): Observable<T> {
    const httpHeaders = this.setHeaders(headers || {});
    const httpParams = this.setParams(params || {});
    endpoint = this.cleanEndpoint(endpoint);
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, body, { headers: httpHeaders, params: httpParams });
  }

  public put<T>(endpoint: string, body?: any, params?: { [key: string]: any }, headers?: { [key: string]: string }): Observable<T> {
    const httpHeaders = this.setHeaders(headers || {});
    const httpParams = this.setParams(params || {});
    endpoint = this.cleanEndpoint(endpoint);
    return this.http.put<T>(`${this.baseUrl}/${endpoint}`, body, { headers: httpHeaders, params: httpParams });
  }

  public delete<T>(endpoint: string, params?: { [key: string]: any }, headers?: { [key: string]: string }): Observable<T> {
    const httpHeaders = this.setHeaders(headers || {});
    const httpParams = this.setParams(params || {});
    endpoint = this.cleanEndpoint(endpoint);
    return this.http.delete<T>(`${this.baseUrl}/${endpoint}`, { headers: httpHeaders, params: httpParams });
  }

  public uploadFile<T>(url: string, file: File, params?: { [key: string]: any }, headers?: { [key: string]: string }): Observable<T> {
    const httpHeaders = this.setHeaders(headers || {});
    const httpParams = this.setParams(params || {});
    return this.http.put<T>(url, file, { headers: httpHeaders, params: httpParams, reportProgress: true });
  }
}
