import {inject, Injectable, OnDestroy, Renderer2, RendererFactory2} from '@angular/core';
import {PracticeRepositoryService} from '../../modules/practice-repository/practice-repository.service';
import {InviteRepositoryService} from '../../modules/invite-repository/invite-repository.service';
import {interval, Subscription} from 'rxjs';
import {Practice} from '../../modules/practice-repository/entities/practice';
import {Invite} from '../../modules/invite-repository/entities/invite';
import {AuthenticationService} from '../auth/authentication.service';
import {SessionStorageService} from '../../modules/storage/session-storage.service';
import {AuthEvent} from '../auth/auth-event';
import {ApplicationConfig} from 'src/app/modules/base/entities/application-config';
import {config} from '../config/app.config';
import {User} from 'src/app/modules/base/entities/user';
import {CommentsRepositoryService} from '../../modules/comments-repository/comments-repository.service';
import {PracticeCommentTemplate} from '../../modules/comments-repository/entities/practice-comment-template';
import {CommentType} from '../../modules/comments-repository/entities/comment-type';
import {ReportTemplateRepositoryService} from '../../modules/report-template-repository/report-template-repository.service';
import {PatientListsRepositoryService} from '../../modules/patient-lists-repository/patient-lists-repository.service';
import {EmailTemplate} from '../../modules/email-template-repository/entities/email-template-entity';
import {EmailTemplateRepositoryService} from '../../modules/email-template-repository/email-template-repository.service';
import {ToastService} from './toast.service';
import {filter} from 'rxjs/operators';
import {UserRepositoryService} from '../../modules/user-repository/user-repository.service';
import {MasterDataRepositoryService} from '../../modules/master-data-repository/master-data-repository.service';
import {GenericCode} from '../../modules/master-data-repository/entities/generic-code';
import {PracticeProcedure} from '../../modules/practice-procedure-repository/entities/practice-procedure';
import {PracticeProcedureRepositoryService} from '../../modules/practice-procedure-repository/practice-procedure-repository.service';
import {PatientRepositoryService} from '../../modules/patient-repository/patient-repository.service';
import {PatientStatus} from '../../modules/patient-repository/entities/patient-status';
import {PatientAppointmentsRepositoryService} from '../../modules/patient-appointments-repository/patient-appointments-repository.service';
import {AppointmentType} from '../../modules/patient-appointments-repository/entities/appointment-type';
import {UserSettings} from '../../modules/user-repository/entities/user-settings';
import {LocalStorageService} from '../../modules/storage/local-storage.service';
import {PracticeSettings} from '../../modules/practice-settings-repository/entities/practice-settings';
import {PracticeSettingsRepositoryService} from '../../modules/practice-settings-repository/practice-settings-repository.service';
import {PracticeUser} from '../../modules/user-repository/entities/PracticeUser';
import {CoreEvent, CoreEventsService} from './core-events.service';
import {PlaceOfService} from '../../modules/master-data-repository/entities/place-of-service';
import {ApplicationBootManager, ApplicationBootStep} from './application-boot-manager';
import {PracticeReportTemplate} from '../../modules/report-template-repository/entities/practice-report-template';
import {WebsocketService} from './websocket.service';
import {PatientFlagEntity} from '../../modules/patient-lists-repository/entities/patient-flag-entity';
import {Letterhead} from '../../modules/practice-letterheads-repository/entities/letterhead';
import {PracticeLetterheadRepositoryService} from '../../modules/practice-letterheads-repository/practice-letterhead-repository.service';

const SUPERUSER = 'Superuser';

@Injectable({
  providedIn: 'root'
})
export class CoreService implements OnDestroy {

  private bootManager = new ApplicationBootManager();
  selectedPractice: Practice = null;
  practices: Practice[] = [];
  invites: Invite[] = [];
  notifications = null;
  appointmentTypes: AppointmentType[] = [];
  commentTemplates: PracticeCommentTemplate[] = [];
  commentTypes: CommentType[] = [];
  reportTemplates: PracticeReportTemplate[] = [];
  emailTemplates: EmailTemplate[] = [];
  patientRaces: GenericCode[] = [];
  patientTitles: GenericCode[] = [];
  patientGenders: GenericCode[] = [];
  patientLanguages: GenericCode[] = [];
  placesOfService: PlaceOfService[] = [];
  practiceProcedures: PracticeProcedure[] = [];
  patientStatuses: PatientStatus[] = [];
  patientFlags: PatientFlagEntity[] = [];
  letterheads: Letterhead[] = [];
  configuration: ApplicationConfig = null;
  practiceSettings: PracticeSettings = null;
  permissions: string[] = null;
  settings: UserSettings = null;
  practiceUser: PracticeUser = null;

  private subscriptions: Subscription[] = [];
  private timerSubscription: Subscription;
  private userVerificationCounter = 0;
  private timerEventsCounter = 0;
  private timerRunning = false;
  private renderer: Renderer2;
  private visibilityChangedListener = null;
  private onlineListener = null;
  private offlineListener = null;
  private isOffline = false;
  private userId = 'public';

  private rendererFactory2 = inject(RendererFactory2);
  private localStorage = inject(LocalStorageService);
  private authService = inject(AuthenticationService);
  private masterDataRepository = inject(MasterDataRepositoryService);
  private patientRepository = inject(PatientRepositoryService);
  private patientListsRepository = inject(PatientListsRepositoryService);
  private practiceRepository = inject(PracticeRepositoryService);
  private practiceSettingsRepository = inject(PracticeSettingsRepositoryService);
  private invitesRepository = inject(InviteRepositoryService);
  private sessionStorage = inject(SessionStorageService);
  private commentsRepository = inject(CommentsRepositoryService);
  private reportTemplateRepository = inject(ReportTemplateRepositoryService);
  private emailTemplateRepository = inject(EmailTemplateRepositoryService);
  private usersRepository = inject(UserRepositoryService);
  private practiceProceduresRepository = inject(PracticeProcedureRepositoryService);
  private appointmentsRepository = inject(PatientAppointmentsRepositoryService);
  private letterheadRepository = inject(PracticeLetterheadRepositoryService);
  private coreEventsService = inject(CoreEventsService);
  private pushService = inject(WebsocketService);
  private toastService = inject(ToastService);

  constructor() {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.subscriptions.push(this.authService.events$
      .pipe(filter(e => e.type === 'login' || e.type === 'logout'))
      .subscribe(event => this.authEventHandler(event)));
    this.configuration = config as ApplicationConfig;
    this.sessionStorage.setItem('app-config', this.configuration);
    this.bindToEvents();
    this.loginProcess();
    this.settings = this.localStorage.getItem('current-user-settings', null);
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  get isSuperuser(): boolean {
    return this.hasPermissions && this.permissions.includes(SUPERUSER);
  }

  /* auth service logic */

  get isReady(): boolean {
    return this.bootManager.isComplete() && this.practices?.length > 0;
  }

  get online(): boolean {
    return this.isOffline === false;
  }

  get hasPermissions(): boolean {
    return this.isAuthenticated && this.permissions != null && this.permissions.length > 0;
  }

  /* core services */

  get procedureMenuItemLabel(): string {
    if (this.practiceProcedures.length !== 1) {
      return 'Procedures';
    }
    return this.practiceProcedures[0].description.length > 0 ? this.practiceProcedures[0].description : 'Procedures';
  }

  get currentUser(): User {
    return this.authService.getUser();
  }

  get firstName(): string {
    return this.authService.getFirstName();
  }

  get surname(): string {
    return this.authService.getSurname();
  }

  get currentProgress(): number {
    return this.bootManager.getCurrentProgress();
  }

  get practiceEventsChannelName(): string {
    return `xmedical_practice_${this.selectedPractice.id}_events`;
  }

  get practicePatientEventsChannelName(): string {
    return `xmedical_practice_${this.selectedPractice.id}_patient_events`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.unbindFromEvents();
  }

  moduleEnabled(module: string): boolean {
    return this.configuration[module + 'Enabled'];
  }

  setTheme(document: HTMLElement, newTheme: string): void {
    document.setAttribute('class', '');
    switch (newTheme) {
      case 'lightblue':
        document.classList.add('theme-blue-light');
        break;
      case 'blue':
        document.classList.add('theme-blue');
        break;
      case 'dark':
      case 'darkblue':
        document.classList.add('theme-blue-dark');
        break;
      case 'darkorange':
        document.classList.add('theme-orange-dark');
        break;
      case 'orange':
      default:
        break;
    }
  }

  authEventHandler(event: AuthEvent) {
    if (event.type === 'login') {
      this.loginProcess();
    }
    if (event.type === 'logout') {
      this.logoutProcess();
    }
  }

  updateConfig(appConfig: ApplicationConfig) {
    this.configuration = appConfig;
  }

  /* master data logic */

  showToast(message: string, delay = 2500) {
    this.toastService.show({message: message, options: { delay: delay }});
  }

  showSuccessToast(message: string, delay = 1500) {
    this.toastService.show({message: message, options: { classname: 'bg-success text-light', delay: delay }});
  }

  showErrorToast(message: string, delay = 5000) {
    this.toastService.show({message: message, options: { classname: 'bg-danger text-light', delay: delay }});
  }

  initMasterDataService() {
    this.loadPatientGenders();
    this.loadPatientLanguages();
    this.loadPatientTitles();
    this.loadPatientRaces();
    this.loadPlacesOfService();
  }

  loadPatientGenders() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientGenders', 'master'));
    this.subscriptions.push(this.masterDataRepository.patientGenders$.subscribe(genders => {
      this.patientGenders = genders;
      this.progressUpdated('loadPatientGenders');
    }));
  }

  /* user specific logic */

  loadPlacesOfService() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPlacesOfService', 'master'));
    this.subscriptions.push(this.masterDataRepository.placesOfService$.subscribe(places => {
      this.placesOfService = places;
      this.progressUpdated('loadPlacesOfService');
    }));
  }

  loadPatientLanguages() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientLanguages', 'master'));
    this.subscriptions.push(this.masterDataRepository.patientLanguages$.subscribe(languages => {
      this.patientLanguages = languages;
      this.progressUpdated('loadPatientLanguages');
    }));
  }

  loadPatientTitles() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientTitles', 'master'));
    this.subscriptions.push(this.masterDataRepository.patientTitles$.subscribe(titles => {
      this.patientTitles = titles;
      this.progressUpdated('loadPatientTitles');
    }));
  }

  loadPatientRaces() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientRaces', 'master'));
    this.subscriptions.push(this.masterDataRepository.patientRaces$.subscribe(races => {
      this.patientRaces = races;
      this.progressUpdated('loadPatientRaces');
    }));
  }

  loadUserSettings() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadUserSettings', 'practice'));
    let hasSettings = false;
    const settings = this.localStorage.getItem(this.userId + '-current-user-settings', null);
    if (settings) {
      this.settings = settings;
      this.coreEventsService.trigger(new CoreEvent('settings_loaded'));
      this.progressUpdated('loadUserSettings');
      hasSettings = true;
    }
    this.subscriptions.push(this.usersRepository.loadCurrentUserSettings().subscribe(loadedSettings => {
      this.settings = loadedSettings;
      this.localStorage.setItem(this.userId + '-current-user-settings', loadedSettings);
      this.coreEventsService.trigger(new CoreEvent('settings_loaded'));
      if (!hasSettings) {
        this.progressUpdated('loadUserSettings');
      }
    }));
  }

  updateUserSettings(settings: UserSettings): Promise<boolean|string> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions.push(this.usersRepository.saveCurrentUserSettings(settings).subscribe(result => {
        if (result) {
          this.settings = settings;
          this.localStorage.setItem(this.userId + '-current-user-settings', settings);
        }
        resolve(result);
      }, error => {
        console.error(error);
        reject(error);
      }));
    });
  }

  loadUserPermissions() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadUserPermissions', 'practice'));
    let hasPermissions = false;
    const permissions = this.localStorage.getItem(this.userId + '-current-user-permissions', []);
    if (permissions.length) {
      this.permissions = permissions;
      this.coreEventsService.trigger(new CoreEvent('permissions_loaded'));
      this.progressUpdated('loadUserPermissions');
      hasPermissions = true;
    }
    this.subscriptions.push(this.usersRepository.loadCurrentUserPermissions().subscribe(loadedPermissions => {
      this.permissions = loadedPermissions;
      this.localStorage.setItem(this.userId + '-current-user-permissions', loadedPermissions);
      this.coreEventsService.trigger(new CoreEvent('permissions_loaded'));
      if (!hasPermissions) {
        this.progressUpdated('loadUserPermissions');
      }
    }));
  }

  loadPracticeSettings() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPracticeSettings', 'practice'));
    let hasSettings = false;
    const settings = this.localStorage.getItem(this.userId + '-practice_settings', null);
    if (settings) {
      this.practiceSettings = settings;
      if (settings.applicationConfiguration) {
        this.updateConfig(settings.applicationConfiguration);
      }
      this.coreEventsService.trigger(new CoreEvent('practice_settings_loaded'));
      this.progressUpdated('loadPracticeSettings');
      hasSettings = true;
    }
    this.subscriptions.push(this.practiceSettingsRepository.load().subscribe(loadedSettings => {
      this.practiceSettings = loadedSettings;
      this.updateConfig(loadedSettings.applicationConfiguration);
      this.localStorage.setItem(this.userId + '-practice_settings', loadedSettings);
      this.coreEventsService.trigger(new CoreEvent('practice_settings_loaded'));
      if (!hasSettings) {
        this.progressUpdated('loadPracticeSettings');
      }
    }));
  }



  updatePracticeSettings(settings: PracticeSettings): Promise<boolean|string> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions.push(this.practiceSettingsRepository.adminUpdate(settings).subscribe(result => {
        if (result) {
          this.practiceSettings = settings;
          this.localStorage.setItem(this.userId + '-practice_settings', settings);
        }
        resolve(result);
      }, error => {
        console.error(error);
        reject(error);
      }));
    });
  }

  hasPermission(permission: string): boolean {
    return this.isAuthenticated && this.permissions != null && this.permissions.length > 0 && this.permissions.includes(permission);
  }

  loadCommentTypes() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadCommentTypes', 'practice'));
    let hasTypes = false;
    const types = this.localStorage.getItem(this.userId + '-comment_types', []);
    if (types.length) {
      this.commentTypes = types;
      this.coreEventsService.trigger(new CoreEvent('comment_types_loaded'));
      this.progressUpdated('loadCommentTypes');
      hasTypes = true;
    }
    this.subscriptions.push(this.commentsRepository.loadPatientCommentTypes().subscribe(loadedTypes => {
      this.commentTypes = loadedTypes;
      this.coreEventsService.trigger(new CoreEvent('comment_types_loaded'));
      this.localStorage.setItem(this.userId + '-comment_types', loadedTypes);
      if (!hasTypes) {
        this.progressUpdated('loadCommentTypes');
      }
    }));
  }

  loadCommentTemplates() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadCommentTemplates', 'practice'));
    let hasTemplates = false;
    const templates = this.localStorage.getItem(this.userId + '-comment_templates', []);
    if (templates.length) {
      this.commentTemplates = templates;
      this.coreEventsService.trigger(new CoreEvent('comment_templates_loaded'));
      this.progressUpdated('loadCommentTemplates');
      hasTemplates = true;
    }
    this.subscriptions.push(this.commentsRepository.loadPatientCommentTemplates().subscribe(loadedTemplates => {
      this.commentTemplates = loadedTemplates;
      this.coreEventsService.trigger(new CoreEvent('comment_templates_loaded'));
      this.localStorage.setItem(this.userId + '-comment_templates', loadedTemplates);
      if (!hasTemplates) {
        this.progressUpdated('loadCommentTemplates');
      }
    }));
  }

  updateCommentTemplate(template: PracticeCommentTemplate): Promise<boolean|string> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions.push(this.commentsRepository.updateTemplate(template).subscribe(result => {
        if (result) {
          const index = this.commentTemplates.findIndex(t => t.id === template.id);
          this.commentTemplates[index] = template;
          this.localStorage.setItem(this.userId + '-comment_templates', this.commentTemplates);
        }
        resolve(result);
      }, error => {
        console.error(error);
        reject(error);
      }));
    });
  }

  loadPracticeLetterheads() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPracticeLetterheads', 'practice'));
    let hasLetterheads = false;
    const letterheads = this.localStorage.getItem(this.userId + '-practice_letterheads', []);
    if (letterheads.length) {
      this.letterheads = letterheads;
      this.coreEventsService.trigger(new CoreEvent('practice_letterheads_loaded'));
      this.progressUpdated('loadPracticeLetterheads');
      hasLetterheads = true;
    }
    this.subscriptions.push(this.letterheadRepository.loadAll().subscribe(loadedLetterheads => {
      this.letterheads = loadedLetterheads;
      this.coreEventsService.trigger(new CoreEvent('practice_letterheads_loaded'));
      this.localStorage.setItem(this.userId + '-practice_letterheads', loadedLetterheads);
      if (!hasLetterheads) {
        this.progressUpdated('loadPracticeLetterheads');
      }
    }));
  }

  loadReportTemplates() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadReportTemplates', 'practice'));
    let hasTemplates = false;
    const templates = this.localStorage.getItem(this.userId + '-report_templates', []);
    if (templates.length) {
      this.reportTemplates = templates;
      this.coreEventsService.trigger(new CoreEvent('report_templates_loaded'));
      this.progressUpdated('loadReportTemplates');
      hasTemplates = true;
    }
    this.subscriptions.push(this.reportTemplateRepository.getReportTemplates().subscribe(loadedTemplates => {
      this.reportTemplates = loadedTemplates;
      this.coreEventsService.trigger(new CoreEvent('report_templates_loaded'));
      this.localStorage.setItem(this.userId + '-report_templates', loadedTemplates);
      if (!hasTemplates) {
        this.progressUpdated('loadReportTemplates');
      }
    }));
  }

  loadProcedures() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadProcedures', 'practice'));
    let hasProcedures = false;
    const procedures = this.localStorage.getItem(this.userId + '-practice_procedures', []);
    if (procedures.length) {
      this.practiceProcedures = procedures;
      this.coreEventsService.trigger(new CoreEvent('practice_procedures_loaded'));
      this.progressUpdated('loadProcedures');
      hasProcedures = true;
    }
    this.subscriptions.push(this.practiceProceduresRepository.loadAll().subscribe(loadedProcedures => {
      this.practiceProcedures = loadedProcedures;
      this.coreEventsService.trigger(new CoreEvent('practice_procedures_loaded'));
      this.localStorage.setItem(this.userId + '-practice_procedures', loadedProcedures);
      if (!hasProcedures) {
        this.progressUpdated('loadProcedures');
      }
    }));
  }

  loadEmailTemplates() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadEmailTemplates', 'practice'));
    let hasTemplates = false;
    const templates = this.localStorage.getItem(this.userId + '-email_templates', []);
    if (templates.length) {
      this.emailTemplates = templates;
      this.coreEventsService.trigger(new CoreEvent('email_templates_loaded'));
      this.progressUpdated('loadEmailTemplates');
      hasTemplates = true;
    }
    this.subscriptions.push(this.emailTemplateRepository.getEmailTemplates().subscribe(loadedTemplates => {
      this.emailTemplates = loadedTemplates;
      this.localStorage.setItem(this.userId + '-email_templates', loadedTemplates);
      this.coreEventsService.trigger(new CoreEvent('email_templates_loaded'));
      if (!hasTemplates) {
        this.progressUpdated('loadEmailTemplates');
      }
    }));
  }

  loadPatientStatuses() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientStatuses', 'practice'));
    let hasStatuses = false;
    const statuses = this.localStorage.getItem(this.userId + '-patient_statuses', []);
    if (statuses.length) {
      this.patientStatuses = statuses;
      this.coreEventsService.trigger(new CoreEvent('patient_statuses_loaded'));
      this.progressUpdated('loadPatientStatuses');
      hasStatuses = true;
    }
    this.subscriptions.push(this.patientRepository.loadStatuses().subscribe(loadedStatuses => {
      this.patientStatuses = loadedStatuses;
      this.localStorage.setItem(this.userId + '-patient_statuses', loadedStatuses);
      this.coreEventsService.trigger(new CoreEvent('patient_statuses_loaded'));
      if (!hasStatuses) {
        this.progressUpdated('loadPatientStatuses');
      }
    }));
  }

  loadPatientFlags() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPatientFlags', 'practice'));
    let hasFlags = false;
    const flags = this.localStorage.getItem(this.userId + '-patient_flags', []);
    if (flags.length) {
      this.patientFlags = flags;
      this.coreEventsService.trigger(new CoreEvent('patient_flags_loaded'));
      this.progressUpdated('loadPatientFlags');
      hasFlags = true;
    }
    this.subscriptions.push(this.patientListsRepository.loadLists().subscribe(loadedFlags => {
      this.patientFlags = loadedFlags;
      this.localStorage.setItem(this.userId + '-patient_flags', loadedFlags);
      this.coreEventsService.trigger(new CoreEvent('patient_flags_loaded'));
      if (!hasFlags) {
        this.progressUpdated('loadPatientFlags');
      }
    }));
  }

  initPracticeService() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('initPracticeService', 'master'));
    this.practices = this.localStorage.getItem('practices', []);
    this.selectedPractice = this.localStorage.getItem('selected_practice', null);
    if (!this.practices.some(practice => practice.id === this.selectedPractice?.id) || this.selectedPractice == null) {
      this.selectedPractice = this.practices[0];
    }
    this.setupPracticePushService();
    this.subscriptions.push(this.practiceRepository.getAllPractices().subscribe(practices => {
      this.practices = practices;
      if (!this.practices.some(practice => practice.id === this.selectedPractice?.id) || this.selectedPractice == null) {
        this.selectedPractice = this.practices[0];
        this.localStorage.setItem('selected_practice', this.selectedPractice);
      }
      this.localStorage.setItem('practices', this.practices);
      this.coreEventsService.trigger(new CoreEvent('practice_loaded'));
      this.progressUpdated('initPracticeService');
      this.loadPracticeData();
    }));
  }

  setupPracticePushService() {
    if (this.selectedPractice == null || this.selectedPractice?.id == null) { return; }
    this.pushService.subscribe(this.practiceEventsChannelName, 'user_settings_changed', _ => {
      this.loadUserSettings();
    });
    this.pushService.subscribe(this.practiceEventsChannelName, 'appointment_types_changed', _ => {
      this.loadAppointmentTypes();
    });
    this.pushService.subscribe(this.practiceEventsChannelName, 'practice_setting_changed', _ => {
      this.loadPracticeSettings();
    });
  }

  resetPracticePushService() {
    if (this.selectedPractice == null || this.selectedPractice?.id == null) { return; }
    this.pushService.unsubscribe(this.practiceEventsChannelName, 'user_settings_changed');
    this.pushService.unsubscribe(this.practiceEventsChannelName, 'appointment_types_changed');
    this.pushService.unsubscribe(this.practiceEventsChannelName, 'practice_setting_changed');
  }

  selectPractice(id: number) {
    if (!this.hasPractices) {
      return;
    }
    this.resetPracticePushService();
    this.clearPracticeData();
    this.selectedPractice = this.practices.find(practice => practice.id === id);
    this.localStorage.setItem('selected_practice', this.selectedPractice);
    this.coreEventsService.trigger(new CoreEvent('practice_change', this.selectedPractice.id));
    this.loadPracticeData();
    this.setupPracticePushService();
  }

  hasPractices(): boolean {
    return this.isAuthenticated && this.practices != null && this.practices.length > 0;
  }

  initInvitesService() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('initInvitesService', 'master'));
    this.subscriptions.push(this.invitesRepository.getAllInvites().subscribe(invites => {
      this.invites = invites;
      this.coreEventsService.trigger(new CoreEvent('invites_loaded'));
      this.progressUpdated('initInvitesService');
    }));
  }

  hasInvites(): boolean {
    return this.isAuthenticated && this.invites != null && this.invites.length > 0;
  }

  /* practice service logic */

  hasNotifications(): boolean {
    return this.hasInvites();
  }

  private loginProcess() {
    if (!this.isAuthenticated) {
      return;
    }
    this.userId = this.authService.getUserId();
    this.verifyUserExists();
    this.setupTimer();
    this.initPracticeService();
    this.initInvitesService();
    this.initMasterDataService();
    if (this.isReady) {
      this.coreEventsService.trigger(new CoreEvent('app_ready'));
    }
  }

  private logoutProcess() {
    this.localStorage.deleteItem('practices');
    this.localStorage.deleteItem('selected_practice');
    this.localStorage.deleteItem(this.userId + '-current-user-settings');
    this.localStorage.deleteItem(this.userId + '-patient-titles');
    this.localStorage.deleteItem(this.userId + '-email_templates');
    this.localStorage.deleteItem(this.userId + '-report_templates');
    this.localStorage.deleteItem(this.userId + '-practice_procedures');
    this.localStorage.deleteItem(this.userId + '-patient_statuses');
    this.localStorage.deleteItem(this.userId + '-comment_templates');
    this.localStorage.deleteItem(this.userId + '-comment_types');
    this.localStorage.deleteItem(this.userId + '-practice_settings');
    this.localStorage.deleteItem(this.userId + '-patient-languages');
    this.localStorage.deleteItem(this.userId + '-current-user-permissions');
    this.practices = [];
    this.invites = [];
    this.notifications = [];
    this.commentTemplates = [];
    this.commentTypes = [];
    this.emailTemplates = [];
    this.permissions = [];
    this.patientRaces = [];
    this.patientGenders = [];
    this.patientLanguages = [];
    this.patientTitles = [];
    this.practiceProcedures = [];
    this.patientStatuses = [];
    this.bootManager.resetSteps();
  }

  private bindToEvents() {
    this.offlineListener = this.renderer.listen('window', 'offline', (event) => {
      this.isOffline = true;
      this.authService.connectivityChanged(navigator.onLine);
      this.coreEventsService.trigger(new CoreEvent('connection_state_changed'));
    });
    this.onlineListener = this.renderer.listen('window', 'online', (event) => {
      this.isOffline = false;
      this.authService.connectivityChanged(navigator.onLine);
      this.coreEventsService.trigger(new CoreEvent('connection_state_changed'));
    });
    this.visibilityChangedListener = this.renderer.listen('document', 'visibilitychange', (event) => {
      if (document.visibilityState === 'visible') {
        this.authService.appFocusChanged('visible');
      }
    });
  }

  /* invites service logic */

  private unbindFromEvents() {
    if (this.offlineListener) {
      this.offlineListener();
    }
    if (this.onlineListener) {
      this.onlineListener();
    }
    if (this.visibilityChangedListener) {
      this.visibilityChangedListener();
    }
  }

  private setupTimer() {
    if (this.timerRunning) {
      return;
    }
    this.timerSubscription = interval(1000).subscribe(() => this.timerEvents());
    this.timerRunning = true;
  }

  /* notifications service logic */

  private stopTimer() {
    if (!this.timerRunning) {
      return;
    }
    this.timerSubscription.unsubscribe();
    this.timerRunning = false;
  }

  /* user logic */

  private timerEvents() {
    if (this.timerEventsCounter < 10) {
      this.timerEventsCounter++;
    } else {
      this.stopTimer();
      this.coreEventsService.trigger(new CoreEvent('app_error'));
    }
  }

  private progressUpdated(step: string) {
    this.bootManager.completeStep(step);
    this.coreEventsService.trigger(new CoreEvent('progress_update'));
    if (this.bootManager.isComplete()) {
      this.coreEventsService.trigger(new CoreEvent('progress_complete'));
    }
    if (this.isReady) {
      this.stopTimer();
      this.coreEventsService.trigger(new CoreEvent('app_ready'));
    }
  }

  private loadAppointmentTypes() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadAppointmentTypes', 'practice'));
    this.subscriptions.push(this.appointmentsRepository.loadAppointmentTypes().subscribe(appointmentTypes => {
      this.appointmentTypes = appointmentTypes;
      this.progressUpdated('loadAppointmentTypes');
      this.coreEventsService.trigger(new CoreEvent('appointment_types_loaded'));
    }));
  }

  private loadPracticeUserProfile() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('loadPracticeUserProfile', 'practice'));
    this.subscriptions.push(this.usersRepository.loadCurrentPracticeUserProfile().subscribe(practiceUser => {
      this.practiceUser = practiceUser;
      this.progressUpdated('loadPracticeUserProfile');
      this.coreEventsService.trigger(new CoreEvent('practice_user_profile_loaded'));
    }));
  }

  private clearPracticeData() {
    this.bootManager.resetPracticeSteps();
    this.practiceSettings = null;
    this.permissions = [];
    this.settings = null;
    this.commentTemplates = [];
    this.commentTypes = [];
    this.reportTemplates = [];
    this.emailTemplates = [];
    this.practiceProcedures = [];
    this.patientStatuses = [];
    this.letterheads = [];
    this.appointmentTypes = [];
    this.practiceUser = null;
  }

  private loadPracticeData() {
    if (this.practices.length) {
      this.loadPracticeSettings();
      this.loadUserPermissions();
      this.loadUserSettings();
      this.loadCommentTemplates();
      this.loadCommentTypes();
      this.loadReportTemplates();
      this.loadEmailTemplates();
      this.loadProcedures();
      this.loadPatientStatuses();
      this.loadPatientFlags();
      this.loadAppointmentTypes();
      this.loadPracticeUserProfile();
      this.loadPracticeLetterheads();
    }
  }

  private verifyUserExists() {
    if (!this.isAuthenticated) {
      return;
    }
    this.bootManager.registerStep(new ApplicationBootStep('verifyUserExists', 'master'));
    this.subscriptions.push(this.usersRepository.verifySystemUserExists(this.authService.getUser()).subscribe(() => {
      this.progressUpdated('verifyUserExists');
    }, error => {
      console.error(error);
      this.userVerificationCounter++;
      if (this.userVerificationCounter > 10) {
        this.coreEventsService.trigger(new CoreEvent('app_error'));
      }
    }));
  }
}

