import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringInitialFormatter',
    standalone: false
})
export class StringInitialFormatterPipe implements PipeTransform {
  private replaceList = [
    'Dr',
    'Inc',
    'Partners',
    'and',
    '&',
  ];
  transform(text: string): string {
    if (text.trim().length === 0) {
      return '';
    }
    text = text.trim();
    text = text.replace(/,/g, '');
    text = text.toLocaleUpperCase();
    // replace common filler words
    this.replaceList.forEach(word => {
      text = text.replace(word.toLocaleUpperCase(), '');
    });
    // replace special characters
    text = text.replace(/[^a-zA-Z ]/g, '');
    // split the string into words
    let words = text.split(' ');
    // filter out empty words
    words = words.filter(word => word.trim().length > 0);
    // return the first letter of each word
    return words.map(word => word[0].toUpperCase()).join('');
  }
}
