import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private localStorageSupported = false;

  constructor() {
    this.localStorageSupported = window.localStorage != null;
  }

  public setItem(key: string, value: any) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public getItem<T>(key: string, defaultValue?: any): any {
    if (this.localStorageSupported) {
      const item = localStorage.getItem(key);
      if (item !== null) {
        try {
          return JSON.parse(item) as T;
        } catch {
          return item;
        }
      }
    }
    return defaultValue || null;
  }

  public updateItem(key: string, value: any) {
    this.setItem(key, value);
  }

  public deleteItem(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    }
  }

}
