import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export const BASE_URL = new InjectionToken('BASE_URL');
export const ADMIN_URL = new InjectionToken('ADMIN_URL');

export function baseUrlFactory() {
    return environment.api;
}

export function adminUrlFactory() {
  return environment.adminApi;
}

export const BASE_URL_PROVIDER = [ { provide: BASE_URL, useFactory: baseUrlFactory } ];
export const ADMIN_URL_PROVIDER = [ { provide: ADMIN_URL, useFactory: adminUrlFactory } ];
