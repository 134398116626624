import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PracticeProcedure} from './entities/practice-procedure';
import {PracticeProcedureCollectionResponse} from './entities/practice-procedure-collection-response';
import {PracticePatientProcedureField} from '../patient-procedure-repository/entities/practice-patient-procedure-field';
import {PracticeProcedureItemResponse} from './entities/practice-procedure-item-response';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeProcedureRepositoryService {

  constructor(private api: ApiService) {

  }


  loadAll(): Observable<PracticeProcedure[]> {
    return this.api.get<PracticeProcedureCollectionResponse>('/procedures/')
      .pipe(map(response => response.items));
  }

  loadFields(procedureId: number): Observable<PracticePatientProcedureField[]> {
    return this.api.get<PracticeProcedureItemResponse>('/procedures/' + procedureId)
      .pipe(map(response => response.value.fields));
  }
}
