import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    standalone: false
})
export class ContainerComponent {

  @Input() fullWidth = false;
  constructor() { }
}
