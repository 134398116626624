import { Component, OnInit, Input } from '@angular/core';
import { ContactNumber } from 'src/app/modules/patient-repository/entities/patient';

@Component({
    selector: 'app-mat-phone-card',
    templateUrl: './app-mat-phone-card.component.html',
    styleUrls: ['./app-mat-phone-card.component.scss'],
    standalone: false
})
export class AppMatPhoneCardComponent implements OnInit {

    @Input() number: string

    constructor() { }

    ngOnInit(): void {
    }

    get telNumber(): string {
        return (this.number && this.number.length) ? this.number : 'Not specified';
    }

}
