import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { pattern, FormatterService } from '../../services/formatter.service';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-mat-form-input',
    templateUrl: './app-mat-form-input.component.html',
    styleUrls: ['./app-mat-form-input.component.scss'],
    standalone: false
})
export class AppMatFormInputComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() color: ThemePalette = 'primary';
    @Input() maxLength = 200;
    @Input() pattern: pattern = 'default';
    @Input() parentForm: UntypedFormGroup;
    @Input() name = '';
    @Input() label = '';
    @Input() value = '';
    @Input() type: inputType = 'text';
    @Input() placeholder = '';
    @Input() helpText = '';
    @Input() errorMessage = '';
    @Input() readonly = false;
    @ViewChild('input') input: ElementRef;
    private element: UntypedFormControl;

    constructor(private formatter: FormatterService) { }

    ngOnInit(): void {
        this.element = this.parentForm.get(this.name) as UntypedFormControl;
        if (this.value.length) { this.element.setValue(this.value); }
        this.element.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.format(this.element.value);
    }

    ngOnChanges(): void {
        if (this.element && this.element.value !== this.value) {
            this.element.setValue(this.value);
            this.element.updateValueAndValidity();
        }
    }

    get required(): boolean {
        const validator = (this.element.validator) ? this.element.validator({} as AbstractControl) : null;
        return !!(validator && validator.required);
    }

    get fieldValid(): boolean {
      const required = this.required || this.element.errors !== null;
      if (!required || this.readonly) { return true; }
      return !this.element.invalid;
    }

    blur(value: string) {
        this.format(value);
    }

    keyUp(value: string) {
        this.format(value);
    }

    paste(value: string) {
        this.format(value);
    }

    private format(value: string) {
        this.input.nativeElement.value = this.formatter.format(value, this.pattern);
    }

}

export type inputType =
    | 'text'
    | 'tel'
    | 'email'
    | 'number';
