import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Appointment} from './entities/appointment';
import {AppointmentCollectionResponse} from './entities/appointment-collection-response';
import {AppointmentType} from './entities/appointment-type';
import {AppointmentTypeCollectionResponse} from './entities/appointment-type-collection-response';
import {DatePipe} from '@angular/common';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {AdminApiService} from '../base/repository/admin-api.service';
import {PatientStatus} from '../patient-repository/entities/patient-status';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';

@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentsRepositoryService {

  constructor(private datePipe: DatePipe, private api: ApiService, private adminApi: AdminApiService) {
  }

  loadAppointmentTypes(): Observable<AppointmentType[]> {
    return this.api.get<AppointmentTypeCollectionResponse>('/appointments/types')
      .pipe(map(response => response.items));
  }

  loadAppointmentsByDay(date: Date): Observable<Appointment[]> {
    const dateString = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.api.get<AppointmentCollectionResponse>('/appointments/day/' + dateString)
      .pipe(map(response => response.items));
  }

  loadPatientAppointments(patientId: number): Observable<Appointment[]> {
    return this.api.get<AppointmentCollectionResponse>('/appointments/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  addAppointmentToPatient(patientId: number, appointment: Appointment): Observable<boolean> {
    return this.api.post<ItemCreatedResponse>('/appointments/patient/' + patientId, appointment)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  addAppointment(appointment: Appointment): Observable<boolean> {
    return this.api.post<ItemCreatedResponse>('/appointments/', appointment)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  updateAppointment(appointment: Appointment): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/appointments/' + appointment.id, appointment)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  deleteAppointment(appointmentId: number): Observable<boolean> {
    return this.api.delete<ItemUpdatedResponse>('/appointments/' + appointmentId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }


  adminLoadAppointmentTypes(): Observable<AppointmentType[]> {
    return this.adminApi.get<AppointmentTypeCollectionResponse>('/appointments/types')
      .pipe(map(response => response.items));
  }

  adminCreate(type: AppointmentType): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/appointments/types', type)
      .pipe(map(response => response.value));
  }

  adminUpdate(id: number, type: AppointmentType): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/appointments/types/' + id, type)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDelete(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/appointments/types/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
