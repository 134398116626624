import { Pipe, PipeTransform } from '@angular/core';
import { ScriptType } from 'src/app/modules/scripts-repository/entities/script-type';

@Pipe({
    name: 'scriptTypeFormatter',
    standalone: false
})
export class ScriptTypeDisplayFormatterPipe implements PipeTransform {
    transform(type: ScriptType): string {
        switch (type) {
            case ScriptType.CHRONIC:
                return 'Chronic';
            case ScriptType.ACUTE:
                return 'Acute';
            default:
                return 'Unknown';
        }
    }
}
