<div class="autocomplete" *appPermissionRequired="'Clinical.Patients.Read'" #host [class.invalid]="isInvalid">
  <mat-form-field [appearance]="appearance" [color]="color" cdkOverlayOrigin #autocomplete="cdkOverlayOrigin" [class.mat-form-field-invalid]="isInvalid" [class.compact]="compact">
    <mat-label>{{placeholder}}</mat-label>
    <input type="text" #searchQuery matInput [formControl]="autocompleteControl"
           appSearchBox [searchObservable]="searchObservable" (results)="onSearchResults($event)" (error)="onError($event)" (searchStatusChange)="onSearchStatusChange($event)">
    <button mat-icon-button matPrefix>
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button matSuffix (click)="cancelSearch()" *ngIf="searchQuery.value.length > 0">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayScrollStrategy]="scrollStrategy" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayWidth]="hostWidth"
               [cdkConnectedOverlayOrigin]="autocomplete" [cdkConnectedOverlayOffsetY]="offsetY" [cdkConnectedOverlayHasBackdrop]="false" [cdkConnectedOverlayOpen]="resultsReady" [cdkConnectedOverlayPanelClass]="'autocomplete-overlay'">
    <div class="autocomplete-results mat-elevation-z3 mat-card-list">
      <ng-container *ngIf="searchResults?.length > 0">
        <mat-card class="compact" *ngFor="let searchResult of searchResults" (click)="selectItem(searchResult)">
          <mat-card-content>
          <ng-container *ngTemplateOutlet="displayResultsCardTemplate; context: { result: searchResult, queryText: queryText }">
          </ng-container>
          </mat-card-content>
          <mat-divider></mat-divider>
        </mat-card>
      </ng-container>
      <mat-card class="compact" *ngIf="searchResults?.length == 0">
        <mat-card-content class="text-center">
          <span>
            No results could be found for your query
          </span>
        </mat-card-content>
        <mat-divider></mat-divider>
      </mat-card>
      <mat-card class="compact" *ngIf="isAddNewItemUsed && (searchResults?.length == 0 || alwaysShowAddCard)">
        <mat-card-content class="text-center mb-0">
          <button mat-button color="primary" class="text-center" (click)="addItem()">{{addItemCardText}}</button>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>
</div>
