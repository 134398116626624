<div class="overlay">
  <form class="popup" [class.form-validated]="validated">
    <h2 class="title">Application Locked</h2>
    <span class="message text-center">Your system has been idle for more than {{timeoutDuration}} minutes.</span>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Account</mat-label>
          <input matInput readonly value="{{emailAddress}}" name="emailAddress"/>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Password</mat-label>
            <input matInput [type]="passwordFieldType" name="password" [(ngModel)]="password" />
          </mat-form-field>
      </div>
      <mat-error *ngIf="!fieldValid">{{errorMessage}}</mat-error>
      <div class="col-12">
        <div class="w-100 d-grid mt-3">
          <button mat-flat-button color="primary" (click)="onClick()">Unlock</button>
        </div>
      </div>
      <div class="col-12">
        <div class="mt-3 d-flex flex-row justify-content-end align-items-center">
          Not you?<button mat-button (click)="onLogout()">Logout</button>
        </div>
      </div>
    </div>
  </form>
</div>
