import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-module-disabled',
    templateUrl: './module-disabled.component.html',
    styleUrls: ['./module-disabled.component.scss'],
    standalone: false
})
export class ModuleDisabledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
