import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Form, AbstractControl } from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';
import {SelectOption} from '../../interfaces/select-option';

@Component({
    selector: 'app-mat-select',
    templateUrl: './app-mat-select.component.html',
    styleUrls: ['./app-mat-select.component.scss'],
    standalone: false
})
export class AppMatSelectComponent implements OnInit {

    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() color: ThemePalette = 'primary';
    @Input() backgroundColor: string;
    @Input() parentForm: UntypedFormGroup;
    @Input() name = '';
    @Input() label = '';
    @Input() helpText = '';
    @Input() default = 'Please select a value';
    @Input() readonly = false;
    @Input() options: SelectOption[] = [];
    @Input() errorMessage = '';
    @Output() valueChanged: EventEmitter<string | number> = new EventEmitter();

    private element: UntypedFormControl;

    constructor() { }

    ngOnInit(): void {
        this.element = this.parentForm.get(this.name) as UntypedFormControl;
        if (this.element.value) {
            this.element.updateValueAndValidity();
        }
    }

    onChange() {
        this.valueChanged.emit(this.element.value);
    }

    get fieldValid(): boolean {
        if (!this.required || this.readonly) { return true; }
        return !this.element.invalid && (this.element.dirty || this.element.touched);
    }

    get required(): boolean {
        const validator = (this.element.validator) ? this.element.validator({} as AbstractControl) : null;
        return !!(validator && validator.required);
    }

    get invalid(): boolean {
        return this.element.invalid;
    }

    get shouldValidate(): boolean {
        return this.element.touched || this.element.dirty;
    }

    get value(): string|number {
      return this.element.value;
    }

    blur(event: any) {
    }
}

// tslint:disable-next-line:no-empty-interface
 export interface Option extends SelectOption {

 }
