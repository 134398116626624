<div class="date-time-field" [formGroup]="parentForm">
  <mat-form-field [appearance]="appearance" [color]="color">
    <mat-label>{{label}}</mat-label>
    <app-mat-date-time-input [formControlName]="name" #dateTimeInput="dateTimeInput"></app-mat-date-time-input>
    <button mat-icon-button matPrefix *ngIf="hasActionObservers" (click)="dateTimeInput.togglePicker()"><mat-icon>today</mat-icon></button>
    <button mat-icon-button matSuffix *ngIf="!hasActionObservers" (click)="dateTimeInput.togglePicker()"><mat-icon>today</mat-icon></button>
    <button mat-button matSuffix *ngIf="hasActionObservers && !processing" color="primary" (click)="performAction()">Save</button>
    <mat-spinner matSuffix diameter="24" *ngIf="processing"></mat-spinner>
  </mat-form-field>
</div>
