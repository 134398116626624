import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, NgOptimizedImage} from '@angular/common';
import {AppMatTextAreaComponent} from './components/app-mat-text-area/app-mat-text-area.component';
import {AppMatSelectComponent} from './components/app-mat-select/app-mat-select.component';
import {AppMatFormInputComponent} from './components/app-mat-form-input/app-mat-form-input.component';
import {LoadingBarComponent} from './components/loading-bar/loading-bar.component';
import {LoadingComponent} from './components/loading/loading.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextHighlightPipe} from './pipes/text-highlight.pipe';
import {AppMatAutocompleteComponent} from './components/app-mat-autocomplete/app-mat-autocomplete.component';
import {ProfilePictureComponent} from './components/profile-picture/profile-picture.component';
import {AppMatTextDisplayComponent} from './components/app-mat-text-display/app-mat-text-display.component';
import {BackButtonDirective} from './directives/back-button.directive';
import {AppMatPhoneCardComponent} from './components/app-mat-phone-card/app-mat-phone-card.component';
import {AppMatEmailCardComponent} from './components/app-mat-email-card/app-mat-email-card.component';
import {AppMatFaxCardComponent} from './components/app-mat-fax-card/app-mat-fax-card.component';
import {DragDropDirective} from './directives/drag-drop.directive';
import {FileSizeFormatterPipe} from './pipes/file-size-formatter.pipe';
import {ExpandedFABComponent} from './components/expanded-fab/expanded-fab.component';
import {ModuleDisabledComponent} from './components/module-disabled/module-disabled.component';
import {TagComponent} from './components/tag/tag.component';
import {NgCdkModule} from '../ng-cdk.module';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {EmailContactArrayFormatterPipe} from './pipes/email-array-display-formatter.pipe';
import {EmailContactFormatterPipe} from './pipes/email-display-formatter.pipe';
import {EmailStatusFormatterPipe} from './pipes/email-status-display-formatter.pipe';
import {MessageStatusFormatterPipe} from './pipes/message-status-formatter.pipe';
import {ScriptTypeDisplayFormatterPipe} from './pipes/script-type-display-formatter.pipe';
import {ActionDisabledComponent} from './components/action-disabled/action-disabled.component';
import {TextSanitizerPipe} from './pipes/text-sanitizer.pipe';
import {ToastsComponent} from './components/toasts/toasts.component';
import {AppMatDateTimePickerComponent} from './components/app-mat-date-time-picker/app-mat-date-time-picker.component';
import {FlatpickrModule} from 'angularx-flatpickr';
import {ConfirmationDialogDirective} from './directives/confirmation-dialog.directive';
import {DialogComponent} from './components/dialog/dialog.component';
import {PermissionRequiredDirective} from './directives/permission-required.directive';
import {TextareaAutosizeDirective} from './directives/textarea-autosize.directive';
import {TextareaJumptextDirective} from './directives/textarea-jumptext.directive';
import {AppLockComponent} from './components/app-lock/app-lock.component';
import {BannerComponent} from './components/banner/banner.component';
import {InvertTextColorDirective} from './directives/invert-text-color.directive';
import {SearchBoxDirective} from './directives/searchbox.directive';
import {InteractiveTextFieldDirective} from './directives/interactive-text-field.directive';
import {TimeDurationPipe} from './pipes/time-duration-pipe.directive';
import {MillisecondsToSecondsPipe} from './pipes/milliseconds-to-seconds.pipe';
import {MaterialSearchBoxComponent} from './components/material-searchbox/material-search-box.component';
import {SearchFilterPipe} from './components/material-searchbox/search-filter-pipe';
import {PatientNumberPadPipe} from './pipes/text-pad.pipe';
import {SpeechToTextDirective} from './directives/speech-to-text-directive';
import {BackgroundColourOpacityDirective} from './directives/background-colour-opacity.directive';
import {MatDateTimeInputComponent} from './components/mat-date-time-input/mat-date-time-input.component';
import {ContainerComponent} from './components/container/container.component';
import {InputFormatterDirective} from './directives/input-formatter.directive';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AppErrorMessageComponent } from './components/app-error-message/app-error-message.component';
import {MatCheckboxBackgroundColourDirective} from './directives/mat-checkbox-background-colour.directive';
import {MatContenteditableTextAreaComponent} from './components/mat-contenteditable-text-area/mat-contenteditable-text-area.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MentionModule} from './components/mat-contenteditable-text-area/utils/lib';
import {StringInitialFormatterPipe} from './pipes/string-initial-formatter.pipe';
import {TextareaHidpiRendererDirective} from './directives/textarea-hidpi-renderer.directive';
import {AspectRatioPercentagePipe} from './pipes/aspect-ratio.pipe';
import { BasicEditorComponent } from './components/basic-editor/basic-editor.component';
import {AngularEditorModule} from '@kolkov/angular-editor';


@NgModule({
  declarations: [
    AppMatTextAreaComponent,
    AppMatSelectComponent,
    AppMatFormInputComponent,
    AppMatAutocompleteComponent,
    AppMatTextDisplayComponent,
    AppMatPhoneCardComponent,
    AppMatEmailCardComponent,
    AppMatFaxCardComponent,
    LoadingBarComponent,
    LoadingComponent,
    TextHighlightPipe,
    TextSanitizerPipe,
    FileSizeFormatterPipe,
    ProfilePictureComponent,
    BackButtonDirective,
    DragDropDirective,
    ExpandedFABComponent,
    TagComponent,
    ModuleDisabledComponent,
    LoadingSpinnerComponent,
    EmailContactArrayFormatterPipe,
    EmailContactFormatterPipe,
    EmailStatusFormatterPipe,
    MessageStatusFormatterPipe,
    ScriptTypeDisplayFormatterPipe,
    ActionDisabledComponent,
    ToastsComponent,
    AppMatDateTimePickerComponent,
    ConfirmationDialogDirective,
    DialogComponent,
    PermissionRequiredDirective,
    TextareaAutosizeDirective,
    TextareaJumptextDirective,
    AppLockComponent,
    BannerComponent,
    InvertTextColorDirective,
    SearchBoxDirective,
    InteractiveTextFieldDirective,
    TimeDurationPipe,
    MillisecondsToSecondsPipe,
    MaterialSearchBoxComponent,
    SearchFilterPipe,
    PatientNumberPadPipe,
    SpeechToTextDirective,
    BackgroundColourOpacityDirective,
    MatDateTimeInputComponent,
    ContainerComponent,
    InputFormatterDirective,
    AppErrorMessageComponent,
    MatCheckboxBackgroundColourDirective,
    MatContenteditableTextAreaComponent,
    StringInitialFormatterPipe,
    TextareaHidpiRendererDirective,
    AspectRatioPercentagePipe,
    BasicEditorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgCdkModule,
    FlatpickrModule.forRoot(),
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    NgOptimizedImage,
    MatChipsModule,
    MatSnackBarModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MentionModule,
    AngularEditorModule,
  ],
  exports: [
    CommonModule,
    BackButtonDirective,
    DragDropDirective,
    ConfirmationDialogDirective,
    AppMatTextAreaComponent,
    AppMatSelectComponent,
    AppMatFormInputComponent,
    AppMatAutocompleteComponent,
    AppMatTextDisplayComponent,
    AppMatPhoneCardComponent,
    AppMatEmailCardComponent,
    AppMatFaxCardComponent,
    LoadingBarComponent,
    LoadingComponent,
    TextHighlightPipe,
    TextSanitizerPipe,
    FileSizeFormatterPipe,
    ProfilePictureComponent,
    ExpandedFABComponent,
    TagComponent,
    ModuleDisabledComponent,
    LoadingSpinnerComponent,
    EmailContactArrayFormatterPipe,
    EmailContactFormatterPipe,
    EmailStatusFormatterPipe,
    MessageStatusFormatterPipe,
    ScriptTypeDisplayFormatterPipe,
    ActionDisabledComponent,
    ToastsComponent,
    AppMatDateTimePickerComponent,
    PermissionRequiredDirective,
    TextareaAutosizeDirective,
    TextareaJumptextDirective,
    SearchBoxDirective,
    InvertTextColorDirective,
    InteractiveTextFieldDirective,
    AppLockComponent,
    BannerComponent,
    TimeDurationPipe,
    MillisecondsToSecondsPipe,
    MaterialSearchBoxComponent,
    PatientNumberPadPipe,
    SpeechToTextDirective,
    BackgroundColourOpacityDirective,
    MatDateTimeInputComponent,
    ContainerComponent,
    InputFormatterDirective,
    AppErrorMessageComponent,
    MatCheckboxBackgroundColourDirective,
    MatContenteditableTextAreaComponent,
    StringInitialFormatterPipe,
    TextareaHidpiRendererDirective,
    AspectRatioPercentagePipe,
    BasicEditorComponent,
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule {
  constructor() {
  }
}
