import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CoreEventsService implements OnDestroy {

  private eventsSubject: Subject<CoreEvent> = new Subject<CoreEvent>();
  events$: Observable<CoreEvent> = this.eventsSubject.asObservable();
  selectedPracticeId: number = null;

  ngOnDestroy(): void {

  }

  get practiceEventsChannelName(): string {
    return `xmedical_practice_${this.selectedPracticeId}_patient_events`;
  }

  trigger(event: CoreEvent): void {
    if (event.type === 'practice_change') {
      this.selectedPracticeId = event.data;
    }
    this.eventsSubject.next(event);
  }
}

export type EventType =
  | 'practice_change'
  | 'patient_change'
  | 'comment_types_loaded'
  | 'comment_templates_loaded'
  | 'report_templates_loaded'
  | 'email_templates_loaded'
  | 'patient_statuses_loaded'
  | 'patient_flags_loaded'
  | 'appointment_types_loaded'
  | 'practice_letterheads_loaded'
  | 'permissions_loaded'
  | 'practice_settings_loaded'
  | 'practice_procedures_loaded'
  | 'practice_user_profile_loaded'
  | 'settings_loaded'
  | 'practice_loaded'
  | 'invites_loaded'
  | 'child_route_loaded'
  | 'progress_update'
  | 'progress_complete'
  | 'app_error'
  | 'app_ready'
  | 'connection_state_changed';

export class CoreEvent {
  constructor(readonly type: EventType, readonly data: any = null) {
  }
}
