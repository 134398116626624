declare global {
  interface String {
    sanitizeUrl(): string;
  }
}

export function sanitizeUrlName(name: string): string {
  return name.trim()
    .replace(/\([^)]*\)/g, '') // Remove text within parentheses
    .replace(/\s\s+/g, ' ') // Replace multiple spaces with a single space
    .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters
    .split(' ')
    .filter(p => p.trim().length > 0).join('-'); // Replace spaces with hyphens
}


String.prototype.sanitizeUrl = function(): string {
  return sanitizeUrlName(this);
};

export {};
