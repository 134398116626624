export abstract class Utilities {
  public static dateToString(date: Date): string {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public static rgbaToHex(rgbaString: string): string {
    if (rgbaString.indexOf('#') === 0) {
      return rgbaString.substring(0, 7);
    }
    const components = this.stringToRgb(rgbaString);
    return '#' + this.componentToHex(components.r) + this.componentToHex(components.g) + this.componentToHex(components.b);
  }

  public static hexToRgba(hex: string, opacity: number): ColorComponents {
    const rgba = this.hexToRgb(hex);
    rgba.a = opacity;
    return rgba;
  }
  public static hexToRgb(hex: string): ColorComponents {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1
    } : {
      r: 0,
      g: 0,
      b: 0,
      a: 1
    };
  }

  public static stringToRgb(rgbString: string): ColorComponents {
    const [r, g, b, a] = rgbString.match(/[\d.]+/g);
    return {
      r: parseInt(r, 10),
      g: parseInt(g, 10),
      b: parseInt(b, 10),
      a: a ? parseFloat(a) : 1
    };
  }

  private static componentToHex(component: number): string {
    const hex = component.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
}

export interface ColorComponents {
  r: number;
  g: number;
  b: number;
  a: number;
}
