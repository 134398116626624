import {Injectable} from '@angular/core';
import {Superuser} from './entities/superuser';
import {Licence} from './entities/licence';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../base/entities/user';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {PermissionsCollectionResponse} from './entities/permissions-collection-response';
import {UserSettingsItemResponse} from './entities/user-settings-item-response';
import {UserSettings} from './entities/user-settings';
import {UsersCollectionResponse} from './entities/users-collection-response';
import {PracticeUser} from './entities/PracticeUser';
import {UserProfileItemResponse} from './entities/user-profile-item-response';
import {AdminApiService} from '../base/repository/admin-api.service';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {UserPermissionsCollectionResponse} from './entities/user-permissions-collection-response';
import {UserPermission} from './entities/user-permission';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {


  constructor(
    private api: ApiService,
    private adminApi: AdminApiService
  ) {
  }

  verifyUserCredentials(password: string): Observable<boolean> {
    const model = {
      password: password
    };
    return this.api.post<ItemCreatedResponse>('users/authenticate', model)
      .pipe(map((result) => result.status.toLocaleUpperCase() === 'OK'));
  }

  verifySystemUserExists(model: User): Observable<number> {
    return this.api.post<ItemCreatedResponse>('users/verify', model)
      .pipe(map((result) => result.value));
  }

  createSuperuser(model: Superuser): Observable<boolean> {
    return this.api.post<ItemCreatedResponse>('users', model)
      .pipe(map((result) => result.status.toLocaleUpperCase() === 'OK'));
  }

  acceptLicenceAgreements(model: Licence): Observable<boolean> {
    return this.api.post<ItemCreatedResponse>('users/licences', model)
      .pipe(map((result) => result.status.toLocaleUpperCase() === 'OK'));
  }

  loadCurrentUserPermissions(): Observable<string[]> {
    return this.api.get<PermissionsCollectionResponse>('users/me/permissions')
      .pipe(map(result => result.items));
  }

  loadCurrentUserSettings(): Observable<UserSettings> {
    return this.api.get<UserSettingsItemResponse>('users/me/settings')
      .pipe(map(result => result.value));
  }

  loadCurrentPracticeUserProfile(): Observable<PracticeUser> {
    return this.api.get<UserProfileItemResponse>('users/me/profile')
      .pipe(map(result => result.value));
  }

  saveCurrentUserSettings(settings: UserSettings): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('users/me/settings', settings)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }

  adminLoadPracticeUsers(): Observable<PracticeUser[]> {
    return this.adminApi.get<UsersCollectionResponse>('users')
      .pipe(map(result => result.items));
  }

  adminLoadPracticeUserPermissions(userId: string): Observable<UserPermission[]> {
    return this.adminApi.get<UserPermissionsCollectionResponse>(`users/${userId}/permissions`)
      .pipe(map(result => result.items));
  }

  adminSavePracticeUserPermissions(userId: string, permissions: UserPermission[]): Observable<boolean> {
    return this.adminApi.post<ItemUpdatedResponse>(`users/${userId}/permissions`, permissions)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }
}
