import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {patientValues} from '../../../modules/patient-repository/entities/patient';
import {MentionConfig} from './utils/lib/mention-config';

@Component({
    exportAs: 'matContenteditableTextArea',
    selector: 'app-mat-contenteditable-text-area',
    templateUrl: './mat-contenteditable-text-area.component.html',
    styleUrls: ['./mat-contenteditable-text-area.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: MatContenteditableTextAreaComponent }],
    standalone: false
})
export class MatContenteditableTextAreaComponent implements MatFormFieldControl<string>, OnInit, OnDestroy, ControlValueAccessor {

  static nextId = 0;
  @Input() multiline = false;
  @Input() insertableValues: InsertableValue[] = [];
  @Input('aria-describedby') userAriaDescribedBy: string;
  focused = false;
  @HostBinding() id = `app-mat-contenteditable-text-area-${MatContenteditableTextAreaComponent.nextId++}`;
  stateChanges = new Subject<void>();
  internalValueChangesSubscription: Subscription;
  contentEditableValueChangesSubscription: Subscription;
  controlType = 'app-mat-contenteditable-text-area';
  autofilled?: boolean;
  mentionConfig: MentionConfig = {
    triggerChar: '@',
    items: this.insertableValues,
    dropUp: false,
    labelKey: 'display',
    mentionFilter(searchString: string, items: InsertableValue[]): InsertableValue[] {
      return items.filter(item => item.display.toLowerCase().includes(searchString.toLowerCase()));
    },
    mentionSelect(item: InsertableValue): string { return  ''; }, // horrific hack to prevent insertion of mention and allow custom insert
  };
  private removeDisabledState = false;
  private textModel = '';

  constructor(
    private _elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty() {
    return this.textModel === '';
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return !this.empty || this.focused;
  }

  get errorState(): boolean {
    return this.ngControl && this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  @Input() get value(): string {
    return this.textModel;
  }

  set value(text: string | null) {
    if (text === null || typeof text === 'string' && text.length === 0) {
      text = '';
    }
    this.editor.innerHTML = this.processPlaceholders(text);
    this.textModel = text;
    this.stateChanges.next();
  }

  get editor(): HTMLElement {
    return this._elementRef.nativeElement.querySelector('.editor');
  }

  private _placeholder: string;

  @Input() get placeholder() {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _required = false;

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input() get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private placeholderElement(placeholder: InsertableValue): HTMLSpanElement {
    const span = document.createElement('span');
    span.className = 'material-placeholder';
    span.textContent = placeholder.display;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('data-placeholder', placeholder.value);
    return span;
  }

  onElementFocused(): void {
    if (this.focused !== true) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onElementTouched(): void {
    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }
  }

  onElementBlur(): void {
    if (this.focused !== false) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  onElementInput(event: Event): void {
    const editorContent = (event.target as HTMLElement).innerHTML;
    this.updateTextModel(editorContent);
  }

  onItemSelect(item: InsertableValue): void {
    this.addPlaceholder(item);
  }

  onChange = (text: string) => {
  }

  onTouched = () => {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.contentEditableValueChangesSubscription?.unsubscribe();
    this.internalValueChangesSubscription?.unsubscribe();
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]): void {
    this.editor?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    this._elementRef.nativeElement.focus();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(text: string): void {
    this.value = text;
  }

  addPlaceholder(placeholder: InsertableValue): void {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const startNode = range.startContainer;
      const startOffset = range.startOffset;
      // Check if the character before the cursor is '@' and remove it
      if (startNode.nodeType === Node.TEXT_NODE && startOffset > 0) {
        const textContent = startNode.textContent;
        const atIndex = textContent.lastIndexOf('@');
        if (atIndex > -1) {
          startNode.textContent = textContent.slice(0, atIndex) + textContent.slice(startOffset);
          // Adjust the range to reflect the removal of '@'
          range.setStart(startNode, atIndex);
          range.setEnd(startNode, atIndex);
        }
      }
      const placeholderElement = this.placeholderElement(placeholder);
      // Insert the placeholder element at the current cursor position
      range.insertNode(placeholderElement);
      // Move the cursor to the end of the inserted element
      const newRange = document.createRange();
      newRange.setStartAfter(placeholderElement);
      newRange.collapse(true); // Collapse the range to the end point, moving the cursor there
      selection.removeAllRanges(); // Clear existing selections
      selection.addRange(newRange); // Set the new range
      // Optionally, update the model or UI as necessary
      this.updateTextModel(this.editor.innerHTML);
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    if (disabled) {
      this.editor.setAttribute('disabled', 'true');
      this.editor.addEventListener('keydown', this.listenerDisabledState);
      this.removeDisabledState = true;
    } else {
      if (this.removeDisabledState) {
        this.editor.removeAttribute('disabled');
        this.editor.removeEventListener('keydown', this.listenerDisabledState);
      }
    }
  }

  private listenerDisabledState(e: KeyboardEvent) {
    e.preventDefault();
  }

  private updateTextModel(editorContent: string) {
    // Replace the badge elements with placeholder text in the model
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = editorContent;
    const placeholders = tempDiv.querySelectorAll('span.material-placeholder');
    const divsAsLinebreaks = tempDiv.querySelectorAll('div');
    const linebreaks = tempDiv.querySelectorAll('br');
    placeholders.forEach(span => {
      const placeholderValue = span.getAttribute('data-placeholder');
      if (placeholderValue) {
        const textNode = document.createTextNode(placeholderValue);
        span.parentNode?.replaceChild(textNode, span);
      }
    });
    divsAsLinebreaks.forEach(div => {
      let content = div.innerHTML.trim();
      content = content.replace(/<br\s*\/?>/gi, '\n');
      if (content) {
        // Create a text node for the div's content, prepending and appending \n
        const contentWithLineBreaks = `\n${content}`;
        const contentNode = document.createTextNode(contentWithLineBreaks);
        div.parentNode?.replaceChild(contentNode, div);
      } else {
        // If the div is empty, just insert a single line break
        const singleLineBreak = document.createTextNode('\n');
        div.parentNode?.replaceChild(singleLineBreak, div);
      }
    });
    linebreaks.forEach(br => {
      const linebreak = document.createTextNode('\n');
      br.parentNode?.replaceChild(linebreak, br);
    });
    this.textModel = tempDiv.textContent || '';
    this.onChange(this.textModel);
  }

  private processPlaceholders(inputString: string): string {
    // Regular expression to find all placeholders with format {{placeholder.Name}} or --placeholder.Name--
    const placeholderRegex = /{{\s*([\w\s.]+)\s*}}|--([\w\s.]+)--/g;
    // Regular expression to match line breaks
    const lineBreakRegex = /\n/g;
    // Replace placeholders with custom span elements
    let processedString = inputString.replace(placeholderRegex, (match, placeholderName1, placeholderName2) => {
      const placeholderName = placeholderName1 || placeholderName2;
      const placeholder = this.insertableValues.find(value => value.value === `{{${placeholderName}}}`);
      if (placeholder) {
        return this.placeholderElement(placeholder).outerHTML;
      } else {
        // If no matching placeholder is found, return the match as is
        return match;
      }
    });
    // Replace line breaks with div elements
    processedString = processedString.replace(lineBreakRegex, '<br>');
    return processedString;
  }
}

export interface InsertableValue {
  value: string;
  display: string;
}
