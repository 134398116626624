import {version} from './version';

export const environment = {
  api: 'https://api.xmedical.app/v1',
  adminApi: 'https://api.xmedical.app/admin/v1',
  production: true,
  serviceWorkerEnabled: true,
  authServer: 'https://accounts.xmedical.app/auth/realms/xmedical_app',
  clientId: 'xmedical-app',
  sessionCheckInterval: 60 * 1000 * 5, // 5 minute session checks
  idleTimeout: 15 * 60 * 1000,
  showDebugInformation: false,
  sessionChecksEnabled: true,
  requireHttps: true,
  scope: 'openid profile email',
  responseType: 'code',
  appTermsLink: 'https://cryptexmedical.com',
  appPrivacyLink: 'https://cryptexmedical.com',
  appSuperuserTermsLink: 'https://cryptexmedical.com',
  version: `${version}`,
  pusher: {
    key: '11dcf043dca34f3ec2cd',
    cluster: 'eu'
  },
  rollbarAccessToken: 'd918e3f5c88648f5ab697304fbd57512',
  rollbarEnvironment: 'production',
  cognitoRegion: 'eu-west-1',
  cognitoIdentityPoolId: 'eu-west-1:8d6c815c-90de-4736-b10c-6eea0f1cbb05'
};
