import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Tab} from 'src/app/core/services/tabs.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {TabCollectionResponse} from './entities/tab-collection-response';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserTabsRepositoryService {

  constructor(private api: ApiService) {
  }

  getTabs(): Observable<Tab[]> {
    return this.api.get<TabCollectionResponse>('usertabs')
      .pipe(map(result => result.items));
  }

  updateTabs(tabs: Tab[]) {
    const model = {
      tabs: tabs
    };
    return this.api.post<ItemUpdatedResponse>('usertabs', model)
      .pipe(map(result => result));
  }

}
