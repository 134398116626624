import {User} from '../../base/entities/user';

export interface Draft {
    patientId: number;
    practiceId: number;
    profileId: number;
    draftType: string;
    draftId: number;
    draftTitle: string;
    draftStatus: DraftStatus;
    createdDate: Date;
    modifiedDate: Date;
    createdUser: User;
    lastModifiedUser: User;
}

export enum DraftStatus {
    OPEN,
    MINIMIZED,
    MAXIMIZED,
    CLOSED
}
