import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-error-message',
    templateUrl: './app-error-message.component.html',
    styleUrls: ['./app-error-message.component.scss'],
    standalone: false
})
export class AppErrorMessageComponent {

  @Input() errorMessage: string;

  get hasErrorMessage(): boolean {
    return this.errorMessage.length > 0;
  }
}
