import { Component, OnInit, Input } from '@angular/core';
import { FormatterService, pattern } from '../../services/formatter.service';

@Component({
    selector: 'app-mat-text-display',
    templateUrl: './app-mat-text-display.component.html',
    styleUrls: ['./app-mat-text-display.component.scss'],
    standalone: false
})
export class AppMatTextDisplayComponent implements OnInit {

    @Input() label = '';
    @Input() value: any = '';
    @Input() pattern: pattern = 'default';

    constructor(private formatter: FormatterService) { }

    ngOnInit(): void {
        this.value = this.formatter.format(this.value, this.pattern);
    }

    get showLabel(): boolean {
      return this.label.length > 0;
    }

    get displayValue(): string {
        return (this.value && this.value.length) ? this.value : 'Not specified';
    }
}
