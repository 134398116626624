<div class="banner {{bgColorClass}}">
  <div class="container">
    <p  class="mb-0 text" appInvertTextColor>{{message}}</p >
    <div class="buttons">
      <button *ngIf="primaryActionText.length" (click)="onPrimaryAction()" mat-button appInvertTextColor [useComputedStyles]="true">{{primaryActionText}}</button>
      <button *ngIf="secondaryActionText.length" (click)="onSecondaryAction()" mat-button appInvertTextColor [useComputedStyles]="true">{{secondaryActionText}}</button>
    </div>
  </div>
</div>
<div class="banner-overlay" *ngIf="useOverlay"></div>
