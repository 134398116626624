import {Directive, ElementRef, HostListener, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DialogComponent} from '../components/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Directive({
    selector: '[appConfirmationDialog]',
    standalone: false
})
export class ConfirmationDialogDirective {

  @Input() confirmTitle = 'Confirm';
  @Input() confirmMessage = 'Are you sure?';
  @Output() confirm = new Subject<void>();

  constructor(private eleRef: ElementRef, private dialog: MatDialog) {}

  @HostListener('click')
  click(e: MouseEvent): void {
    const dialogRef = this.dialog.open(DialogComponent, { data: { title: this.confirmTitle, message: this.confirmMessage } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) { this.confirm.next(); }
    });
  }

}
