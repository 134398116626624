import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aspectRatioPercentage',
  pure: true,
  standalone: false
})
export class AspectRatioPercentagePipe implements PipeTransform {
  transform(height: number, width: number): number {
    return (height / width) * 100;
  }
}
