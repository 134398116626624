import { Pipe, PipeTransform } from "@angular/core";
import { EmailStatus } from "src/app/modules/patient-emails-repository/entities/email-status";

@Pipe({
    name: 'emailStatusFormatter',
    standalone: false
})
export class EmailStatusFormatterPipe implements PipeTransform {
    transform(status: EmailStatus): string {
        switch(status){
            case EmailStatus.SENT:
                return "Sent Successfully";
            case EmailStatus.DRAFT:
                return "Draft";
            case EmailStatus.FAILED:
                return "Failed to send";
            case EmailStatus.PENDING:
                return "Email in outbox";
            default:
                return "An unknown status was returned"
        }
    }
}