import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'appMsToSeconds',
    standalone: false
})
export class MillisecondsToSecondsPipe implements PipeTransform {
  transform(value: number): number {
    if (value === 0) {
      return 0;
    }
    return value / 1000;
  }
}
