import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {TitlesCollectionResponse} from './entities/titles-collection-response';
import {GenericCode} from './entities/generic-code';
import {GenderCollectionResponse} from './entities/gender-collection-response';
import {LanguagesCollectionResponse} from './entities/languages-collection-response';
import {RacesCollectionResponse} from './entities/races-collection-response';
import {PlacesOfServiceCollectionResponse} from './entities/places-of-service-collection-response';
import {PlaceOfService} from './entities/place-of-service';
import {VatRate} from './entities/vat-rate';
import {VatRateItemResponse} from './entities/vat-rate-item-response';
import {TariffCode} from './entities/tariff-code';
import {DiagnosisCode} from './entities/diagnosis-code';
import {DiagnosisCodeCollectionResponse} from './entities/diagnosis-code-collection-response';
import {TariffCodeCollectionResponse} from './entities/tariff-code-collection-response';
import {ApiService} from '../base/repository/api.service';
import {DatePipe} from '@angular/common';
import {AuthenticationService} from '../../core/auth/authentication.service';
import {MasterPermission} from './entities/master-permission';
import {MasterPermissionCollectionResponse} from './entities/master-permission-collection-response';

@Injectable({
  providedIn: 'root'
})
export class MasterDataRepositoryService {
  private patientRaces: GenericCode[] = [];
  private patientRacesSubject: BehaviorSubject<GenericCode[]> = new BehaviorSubject(this.patientRaces);
  patientRaces$: Observable<GenericCode[]> = this.patientRacesSubject.asObservable();
  private patientTitles: GenericCode[] = [];
  private patientTitlesSubject: BehaviorSubject<GenericCode[]> = new BehaviorSubject(this.patientTitles);
  patientTitles$: Observable<GenericCode[]> = this.patientTitlesSubject.asObservable();
  private patientGenders: GenericCode[] = [];
  private patientGendersSubject: BehaviorSubject<GenericCode[]> = new BehaviorSubject(this.patientGenders);
  patientGenders$: Observable<GenericCode[]> = this.patientGendersSubject.asObservable();
  private patientLanguages: GenericCode[] = [];
  private patientLanguagesSubject: BehaviorSubject<GenericCode[]> = new BehaviorSubject(this.patientLanguages);
  patientLanguages$: Observable<GenericCode[]> = this.patientLanguagesSubject.asObservable();
  private placesOfService: PlaceOfService[] = [];
  private placesOfServiceSubject: BehaviorSubject<PlaceOfService[]> = new BehaviorSubject(this.placesOfService);
  placesOfService$: Observable<PlaceOfService[]> = this.placesOfServiceSubject.asObservable();
  private vatRate: VatRate = null;
  private vatRateSubject: BehaviorSubject<VatRate> = new BehaviorSubject(this.vatRate);
  vatRate$: Observable<VatRate> = this.vatRateSubject.asObservable();
  private _permissions: MasterPermission[] = [];
  private permissionsSubject: BehaviorSubject<MasterPermission[]> = new BehaviorSubject(this._permissions);
  permissions$: Observable<MasterPermission[]> = this.permissionsSubject.asObservable();
  get permissions(): MasterPermission[] {
    return this._permissions;
  }

  constructor(private apiService: ApiService, private datePipe: DatePipe, private authService: AuthenticationService) {
    const subscription = this.authService.events$
      .pipe(filter(event => event.type === 'login' || event.type === 'logout')).subscribe((event) => {
        if (event.type === 'login') { this.loadData(); }
        if (event.type === 'logout') { this.clearData(); }
        subscription.unsubscribe();
      });
    if (this.authService.isAuthenticated()) {
      this.loadData();
    }
  }

  private clearData() {
    this.patientRaces = [];
    this.patientRacesSubject.next(this.patientRaces);
    this.patientTitles = [];
    this.patientTitlesSubject.next(this.patientTitles);
    this.patientGenders = [];
    this.patientGendersSubject.next(this.patientGenders);
    this.patientLanguages = [];
    this.patientLanguagesSubject.next(this.patientLanguages);
    this.placesOfService = [];
    this.placesOfServiceSubject.next(this.placesOfService);
    this.vatRate = null;
    this.vatRateSubject.next(this.vatRate);
    this._permissions = [];
    this.permissionsSubject.next(this._permissions);
  }

  private loadData() {
    this.loadPatientLanguages();
    this.getPatientTitles();
    this.getPatientGenders();
    this.getPatientLanguages();
    this.getPatientRaces();
    this.getPlacesOfService();
    this.getVatRate();
    this.getPermissions();
  }

  private loadPatientLanguages() {
    const subscription = this.apiService.get<LanguagesCollectionResponse>('masterdata/patient-languages')
      .subscribe((result) => {
        this.patientLanguages = result.items;
        this.patientLanguagesSubject.next(this.patientLanguages);
        subscription.unsubscribe();
      });
  }

  private getPatientTitles() {
    const subscription = this.apiService.get<TitlesCollectionResponse>('masterdata/patient-titles')
      .subscribe(response => {
        this.patientTitles = response.items;
        this.patientTitlesSubject.next(this.patientTitles);
        subscription.unsubscribe();
      });
  }

  private getPatientGenders() {
    const subscription = this.apiService.get<GenderCollectionResponse>('masterdata/patient-genders')
      .subscribe(response => {
        this.patientGenders = response.items;
        this.patientGendersSubject.next(this.patientGenders);
        subscription.unsubscribe();
      });
  }

  private getPatientLanguages() {
    const subscription = this.apiService.get<LanguagesCollectionResponse>('masterdata/patient-languages')
      .subscribe(response => {
        this.patientLanguages = response.items;
        this.patientLanguagesSubject.next(this.patientLanguages);
        subscription.unsubscribe();
      });
  }

  private getPatientRaces() {
    const subscription = this.apiService.get<RacesCollectionResponse>('masterdata/patient-races')
      .subscribe(response => {
        this.patientRaces = response.items;
        this.patientRacesSubject.next(this.patientRaces);
        subscription.unsubscribe();
      });
  }

  private getPlacesOfService() {
    const subscription = this.apiService.get<PlacesOfServiceCollectionResponse>('masterdata/places-of-service')
      .subscribe(response => {
        this.placesOfService = response.items;
        this.placesOfServiceSubject.next(this.placesOfService);
        subscription.unsubscribe();
      });
  }

  getVatRate(date: Date | string = new Date()) {
    if (date instanceof Date) {
      date = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    const subscription = this.apiService.get<VatRateItemResponse>('masterdata/vat-rate', {'queryDate': date})
      .subscribe(response => {
        this.vatRate = response.value;
        this.vatRateSubject.next(this.vatRate);
        subscription.unsubscribe();
      });
  }

  searchTariffCodes(query: string, discipline: string, isSpecialist: boolean): Observable<TariffCode[]> {
    return this.apiService.get<TariffCodeCollectionResponse>('masterdata/tariff-codes/search/', {'query': query, 'disciplineCode': discipline, 'isSpecialist': isSpecialist})
      .pipe(map(response => response.items));
  }

  searchDiagnosisCodes(query: string): Observable<DiagnosisCode[]> {
    return this.apiService.get<DiagnosisCodeCollectionResponse>('masterdata/diagnosis-codes/search/', {'query': query})
      .pipe(map(response => response.items));
  }

  getPermissions() {
    const subscription = this.apiService.get<MasterPermissionCollectionResponse>('masterdata/permissions')
      .subscribe(response => {
        this._permissions = response.items;
        this.permissionsSubject.next(this._permissions);
        subscription.unsubscribe();
      });
  }
}
