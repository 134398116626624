import {Injectable} from '@angular/core';
import {NewPractice, Practice} from './entities/practice';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PracticeCollectionResponse} from './entities/practice-collection-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class PracticeRepositoryService {

  constructor(private api: ApiService) {
  }

  getAllPractices(): Observable<Practice[]> {
    return this.api.get<PracticeCollectionResponse>('/practices')
      .pipe(map(response => response.items));
  }

  addPractice(model: NewPractice): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/practices/setup/practice', model)
      .pipe(map((result) => result.value));
  }

  updatePractice(model: Practice): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('/practices/' + model.id, model)
      .pipe(map(result => result.status.toLocaleUpperCase() === 'OK'));
  }
}
