export class ApplicationBootManager {
  private readonly steps: ApplicationBootStep[] = [];
  private completedSteps: string[] = [];

  constructor(initialSteps: ApplicationBootStep[] = []) {
    this.steps = initialSteps;
    this.completedSteps = [];
  }

  private get totalSteps(): number {
    return this.steps.length;
  }

  private get processedSteps(): number {
    return this.completedSteps.length;
  }

  registerStep(step: ApplicationBootStep): void {
    if (!this.steps.some(existingStep => existingStep.step === step.step)) {
      this.steps.push(step);
    }
  }

  completeStep(stepName: string): void {
    if (!this.completedSteps.includes(stepName)) {
      this.completedSteps.push(stepName);
    }
  }

  isComplete(): boolean {
    return this.processedSteps === this.totalSteps;
  }

  resetSteps(): void {
    this.completedSteps = [];
  }

  resetPracticeSteps(): void {
    const practiceStepNames = this.steps.filter(step => step.type === 'practice').map(step => step.step);
    this.completedSteps = this.completedSteps
      .filter(step => !practiceStepNames.includes(step));
  }

  getCurrentProgress(): number {
    return (this.processedSteps / this.totalSteps) * 100;
  }
}

export class ApplicationBootStep {
  constructor(readonly step: string, readonly type: ApplicationBootStepType) {}
}

export type  ApplicationBootStepType =
 | 'master'
 | 'practice';
