import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MentionDirective } from './mention.directive';
import { MentionListComponent } from './mention-list.component';
import {MatList, MatListItem} from '@angular/material/list';

@NgModule({
    declarations: [
        MentionDirective,
        MentionListComponent
    ],
  imports: [
    CommonModule,
    MatList,
    MatListItem
  ],
    exports: [
        MentionDirective
    ]
})
export class MentionModule { }
