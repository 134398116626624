import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-action-disabled',
    templateUrl: './action-disabled.component.html',
    styleUrls: ['./action-disabled.component.scss'],
    standalone: false
})
export class ActionDisabledComponent implements OnInit {
    
    @Input() disabledTitle: string = "Disabled"
    @Input() disabledMessage: string = "This functionality is currently disabled"
    @Output() buttonClicked = new EventEmitter()
    active = false
    visible = false
  
    constructor() { }
  
    ngOnInit(): void {
    }
  
    show(message?: string){    
      this.active = true
      if (message){
          this.disabledMessage = message
      }
      this.visible = true;
    }
  
    isVisible(): boolean {
        return this.visible
    }
  
    onClick(){
      this.visible = false;
      this.buttonClicked.emit()
    }

}
